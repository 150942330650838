<template>
  <b-modal id="agent-send-config-modal" v-model="showModal" centered size="lg" title="Send configuration parameter to agent">

    <AgentsSelect :agents-list="agentsList" v-model="selectedAgent" />

    <b-form-row class="align-items-center mt-3">
      <b-col cols="auto"  lg="2"><label class="col-form-label">Parameter:</label></b-col>
      <b-col cols="5"><b-form-input v-model="paramKey" placeholder="Key" /></b-col>
      <b-col cols="auto">=</b-col>
      <b-col cols="3"><b-form-input v-model="paramValue" placeholder="Value" /></b-col>
    </b-form-row>

    <template #modal-footer>
      <b-button variant="secondary" @click="showModal=false">Close</b-button>
      <RefreshButton default-label="Send" loading-label="Sending" :loading="sending" no-default-icon @click="sendConfig"/>
    </template>

  </b-modal>
</template>


<script>
import AgentsSelect from "@/components/agents/AgentsSelect";
import RefreshButton from "@/components/common/RefreshButton";
import axios from "axios";
import {showAxiosErrorToast, showErrorToast, showInfoToast} from "@/util/toasts";

export default {
  name: 'SendConfigModal',

  components: {
    AgentsSelect,
    RefreshButton,
  },

  props: {
    agentsList: Array,
    clickedAgent: Object,
  },

  data: function() {
    return {
      showModal: false,
      selectedAgent: null,
      sending: false,
      paramKey: '',
      paramValue: '',
    }
  },

  methods: {
    sendConfig: function() {
      if (!this.paramKey || this.paramKey.trim().length === 0) {
        showErrorToast("Parameter key can not be blank");
        return;
      }
      let postBody = {
        targetDevice: this.selectedAgent,
        key: this.paramKey,
        value: this.paramValue,
      };
      this.sending = true;
      axios.post('/api/agent/config', postBody)
          .then( response => showInfoToast("Command sent! Command id: " + response.data.commandId) )
          .catch( error => showAxiosErrorToast("Failed to send configuration to agent", error) )
          .finally( () => this.sending = false )
    }
  },

  watch: {
    clickedAgent: function() {
      this.selectedAgent = this.clickedAgent.id;
    },
  }


}
</script>