import {DateTime} from "luxon";

export function timeIntervalFromPretty(dateTimeFrom, now) {
  if (!now) {
    now = DateTime.now()
  }
  let interval = now.diff(dateTimeFrom);

  let months = interval.as('months') | 0;
  if (months > 0) {
    return months + " month(s) ago"
  }
  let days = interval.as('days') | 0;
  if (days > 0) {
    return days + " day(s) ago";
  }
  let hours = interval.as('hours') | 0;
  if (hours > 0) {
    return hours + " hour(s) ago";
  }
  let minutes = interval.as('minutes') | 0;
  if (minutes > 0) {
    return minutes + " minute(s) ago"
  }
  return (interval.as('seconds') | 0) + " sec(s) ago";
}

