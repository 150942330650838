<template>
  <b-container class="container mt-2">
      <h1>Dashboard</h1>

    <b-card-group columns>
      <PresetsControl :presets="presets" @force-updaate="forceUpdateDashboard" />
      <HomeActivityControl :home-activity-data="homeActivityData"/>
      <HeatingControl :heating-status-full="heatingStatus" @force-update="forceUpdateDashboard" />
      <LightsControl :lights-status="lights" @force-update="forceUpdateDashboard" />
      <VentilationControl :ventilation-status="ventilationStatus" @force-update="forceUpdateDashboard" />
      <PowerOutletsControl :power-outlets-status="powerOutlets" @force-update="forceUpdateDashboard" />
      <ClimateDashboardControl :climate-data="climateData" />
      <MetersDashboardControl :meters-data="metersData" />
    </b-card-group>

  </b-container>
</template>


<script>
import PresetsControl from "@/components/dashboard/PresetsControl"
import HeatingControl from "@/components/heating/HeatingControl"
import LightsControl from "@/components/lights/LightsControl"
import VentilationControl from "@/components/dashboard/VentilationControl";
import axios from 'axios'
import {showAxiosErrorToast} from '@/util/toasts'
import PowerOutletsControl from "@/components/dashboard/PowerOutletsControl";
import ClimateDashboardControl from "@/components/climate/ClimateDashboardControl";
import MetersDashboardControl from "@/components/meters/MetersDashboardControl";
import HomeActivityControl from "@/components/dashboard/HomeActivityControl";


export default {
  name: 'DashboardPage',
  components: {
    HeatingControl,
    PresetsControl,
    LightsControl,
    VentilationControl,
    PowerOutletsControl,
    ClimateDashboardControl,
    MetersDashboardControl,
    HomeActivityControl,
  },

  props: {
  },

  data: function() {
    return {
      presets: null,
      heatingStatus: null,
      lights: null,
      ventilationStatus: null,
      powerOutlets: null,
      climateData: null,
      metersData: null,
      homeActivityData: null,

      mounted: false,
      timerId: null,
    }
  },

  async created() {
    this.mounted = true;
    await this.fetchDashboardData();
  },

  destroyed() {
    this.mounted = false;
  },


  methods: {
    async fetchDashboardData() {
      try {
        const dashboardResponse = await axios.get('/api/dashboard');
        this.presets = dashboardResponse.data.presets;
        this.heatingStatus = dashboardResponse.data.heatingStatus;
        this.lights = dashboardResponse.data.lightsStatus;
        this.ventilationStatus = dashboardResponse.data.ventilationStatus;
        this.powerOutlets = dashboardResponse.data.powerOutlets;
        this.climateData = dashboardResponse.data.climate;
        this.metersData = dashboardResponse.data.meters;
        this.homeActivityData = dashboardResponse.data.homeActivity;
      } catch (error) {
        showAxiosErrorToast("Failed to fetch dashboard data", error);
      }
      if (this.mounted) {
        this.timerId = setTimeout(this.fetchDashboardData, 5000);
      }
    },

    async forceUpdateDashboard() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      await this.fetchDashboardData();
    },
  },

}

</script>

<style scoped>

/* bootstrap XS */
@media (min-width: 576px) {
  .card-columns {
    column-count: 1;
  }
}

/* bootstrap SM */
@media (min-width: 768px) {
  .card-columns {
    column-count: 2;
  }
}

/* bootstrap MD */
@media (min-width: 992px) {
  .card-columns {
    column-count: 3;
  }
}

/* bootstrap LG */
@media (min-width: 1200px) {
  .card-columns {
    column-count: 3;
  }
}

</style>