<template>
  <b-button :variant="variant" @click="$emit('click')" :disabled="loading">
    <section v-if="loading">
      <b-spinner small></b-spinner>
      {{loadingLabel}}...
    </section>
    <section v-else>
      <BIconArrowRepeat v-if="!noDefaultIcon" rotate="45"/>
      {{defaultLabel}}
    </section>
  </b-button>
</template>


<script>
import {BIconArrowRepeat} from "bootstrap-vue";

export default {
  name: 'RefreshButton',
  props: {
    loading: Boolean,
    defaultLabel: { type: String, default: 'Refresh' },
    loadingLabel: { type: String, default: 'Loading' },
    variant: { type: String, default: 'success' },
    noDefaultIcon: { type: Boolean, default: false }
  },

  components: {
    BIconArrowRepeat
  },

}
</script>