<template>
  <b-container class="container mt-2">
    <AgentsListControl />
    <CommandsListControl class="mt-4" />
  </b-container>
</template>


<script>
import AgentsListControl from "@/components/agents/AgentsListControl";
import CommandsListControl from "@/components/agents/CommandsListControl";

export default {
  name: 'AgentsPage',
  components: {
    CommandsListControl,
    AgentsListControl,
  }
}
</script>