import { defineStore } from "pinia";
import axios from "axios";

export const useGlobalConfigStore = defineStore('globalConfig', {
  state: () => {
    return {
      grafanaUrl: null,
    }
  },

  actions: {
    fetchGlobalConfig: async function() {
      console.info("Fetching global config")
      return axios.get('/api/config/global_config')
        .then((response) => {
          console.log("Global config fetched");
          this.grafanaUrl = response.data.grafanaUrl;
        })
        .catch(er => console.error("Failed to fetch global config: " + er.message));
    }
  },
});