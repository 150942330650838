<template>
  <div v-if="chartData" class="line-chart mt-3">
    <b-overlay :show="loading" rounded="sm" spinner-variant="primary" spinner-type="grow">
      <LineChart
          :options="chartOptions"
          :data="chartData"
          :height="250"
      />
    </b-overlay>
  </div>
</template>


<script>
import { Line as LineChart } from 'vue-chartjs'
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, TimeScale, CategoryScale, Title, Tooltip, Legend } from 'chart.js'
import 'chartjs-adapter-luxon'
import {DateTime} from "luxon";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, TimeScale, CategoryScale, Title, Tooltip, Legend)

const Colors = [
  'rgb(58, 168, 7)',
  'rgb(7, 20, 168)',
  'rgb(144, 7, 168)',
  'rgb(247, 104, 27)',
  'rgb(27, 240, 247)',
  'rgb(247, 27, 38)',
  'rgb(247, 233, 27)',
];

export default {
  name: 'ClimateChartControl',
  components: {
    LineChart
  },

  props: {
    loading: Boolean,
    title: String,
    climateComponent: String,
    units: String,
    chartPoints: Array,
  },

  methods: {
    chartTooltipLabelCallback: function(context) {
      let label = context.dataset.label || '';

      if (label) {
        label += ': ';
      }
      if (context.parsed.y !== null) {
        label += Math.round(context.parsed.y * 10) / 10;
        label += this.units;
      }
      return label;
    }
  },

  computed: {
    chartOptions: function() {
      return {
        plugins: {
          title: {
            display: true,
            text: this.title,
          },
          tooltip: {
            intersect: false,
            mode: 'x',
            callbacks: {
              label: this.chartTooltipLabelCallback,
            },
          },
        },
        scales: {
          x: {
            type: 'time',
            distribution: 'linear',
            max: DateTime.now().toSeconds(),
            time: {
              parser: (x) => (x * 1000),
              displayFormats: {
                hour: "d LLL HH:mm",
              },
            }
          },
          y: {
            title: {
              display: true,
              text: this.units,
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    },

    chartData: function() {
      if (!this.chartPoints) {
        return null;
      }
      let datasets = this.chartPoints.map( (loc, idx) => (
          {
            'data': loc.points.map( p => ( { 'x': p.ts, 'y': p[this.climateComponent] } ) ),
            'borderColor': Colors[idx % Colors.length],
            'backgroundColor': Colors[idx % Colors.length],
            'pointRadius': 3,
            'label': loc.locationDisplayName,
            'tension': 0.3,
          }
      ) );
      return {
        datasets: datasets,
      };
    },
  }
}
</script>


<style scoped>
.line-chart {
  max-width: 600px;
}
</style>