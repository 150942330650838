<template>
  <b-container class="container mt-2">
    <div class="d-flex align-items-center gap-1">
      <h1>Photo album</h1>
      <RefreshButton variant="outline-success" :loading="loading" @click="reloadAlbum" />
    </div>

    <div class="row mb-3">
      <div v-for="(photo, index) in photos" v-bind:key="photo.reference" class="col-auto">
        <b-card no-body
            class="mb-2 photo-card"
            :img-src="photo.previewLink"
            img-alt="preview"
            img-top
            @click="onPhotoClicked(photo, index)"
            role="button"
        >
          <b-card-text class="p-2">
            <b>{{ photo.location }}</b>
            <br>
            {{ photo.createdAt | toLocalDateTime }}
          </b-card-text>
        </b-card>
      </div>
    </div>

    <PhotoModal
        :photo="selectedPhoto"
        :current-photo-index="selectedIndex"
        @previous-photo="previousPhoto"
        @next-photo="nextPhoto"
    />
  </b-container>
</template>


<script>
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";
import {DateTime} from "luxon";
import RefreshButton from "@/components/common/RefreshButton";
import PhotoModal from "@/components/camera/PhotoModal";

export default {
  name: 'PhotoAlbumPage',
  components: {
    PhotoModal,
    RefreshButton,
  },

  data: function() {
    return {
      loading: true,
      photos: [],
      selectedIndex: null,
    }
  },

  created: function() {
    this.reloadAlbum();
  },

  methods: {
    reloadAlbum: function() {
      this.loading = true;
      axios.get('/api/media_storage/photos')
          .then(response => this.photos = response.data.photos)
          .catch(error => showAxiosErrorToast('Failed to fetch photos', error))
          .finally(() => this.loading = false);
    },

    onPhotoClicked: function(photo, index) {
      this.selectedIndex = index;
      this.$bvModal.show('photo-modal');
    },

    nextPhoto: function() {
      if (this.photos && this.photos.length > 0) {
        if (this.selectedIndex + 1 < this.photos.length) {
          ++this.selectedIndex;
        }
      }
    },

    previousPhoto: function() {
      if (this.photos && this.photos.length > 0) {
        if (this.selectedIndex - 1 >= 0) {
          --this.selectedIndex;
        }
      }
    },

  },

  computed: {
    selectedPhoto: function() {
      if (this.photos && this.selectedIndex >= 0 && this.selectedIndex < this.photos.length) {
        return this.photos[this.selectedIndex];
      } else {
        return null;
      }
    }
  },

  filters: {
    toLocalDateTime: function(value) {
      if (value) {
        return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      }
    },
  },
}
</script>


<style scoped>
  .photo-card {
    width: 14rem;
  }

  .photo-card:hover {
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border-color: black;
  }

  .gap-1 {
    gap: 1em;
  }
</style>