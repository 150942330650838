import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import {createPinia, PiniaVuePlugin} from 'pinia'
import VueRouter from 'vue-router'
import {AllRoutes} from './routes'
import axios from 'axios'
import {useUserStore} from '@/stores/user';
import {useGlobalConfigStore} from "@/stores/global_config";

Vue.config.productionTip = false

console.log("Environment: " + process.env.NODE_ENV);
if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

Vue.use(VueRouter);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();
const userStore = useUserStore(pinia);
const globalConfig = useGlobalConfigStore(pinia)

// Not waiting for the response. Errors are ignored. Can fail with 401 if user is not authenticated
// It should be changed if config contains any critical parameters for webapp
void globalConfig.fetchGlobalConfig()

userStore.$subscribe(() => {
  // Request global config every time user logs in.
  if (userStore.isLoggedIn) {
    void globalConfig.fetchGlobalConfig()
  }
});

const router = new VueRouter({
  routes: AllRoutes
});

const DefaultTitle = 'CozyCave NG';
router.beforeEach(async (to, from, next) => {
  let redirectToLogin = !userStore.isLoggedIn && to.name !== 'Login';
  if (redirectToLogin) {
    // maybe we have a valid cookie but just not aware of it?
    await userStore.fetchUserDetails();
    redirectToLogin = !userStore.isLoggedIn;
  }
  if (redirectToLogin) {
    next({ name: 'Login', query: { redirect_to: to.fullPath } });
  } else {
    document.title = to.meta.title || DefaultTitle;
    next();
  }
});

axios.interceptors.request.use(
  (config) => config,

  (error) => {
    console.error("AXIOS Request Error: " + error);
    console.log(error.toJSON());
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      if (router.currentRoute.path !== '/login' && !error.config.url.startsWith('/api/auth/')) {
        console.warn('Not authorised - redirecting to Login page');
        router.push({ name: 'Login' })
      } else {
        console.log("Login page/api - no redirection");
      }
    }
    return Promise.reject(error);
  }
);

export const app = new Vue({
  render: h => h(App),
  pinia,
  router,
}).$mount('#app')
