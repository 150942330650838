<template>
  <b-container class="container mt-2">
    <b-alert show>
      Currently on this page you can view <b>heater runs history</b> only.
      Use <b-link class="alert-link" :to="{ name: 'Dashboard' }">dashboard page</b-link> to control the heating system.
    </b-alert>

    <div class="d-flex align-items-center">
      <h2>Heater runs</h2>
      <RefreshButton class="ml-3" variant="outline-success" :loading="loading" @click="refreshData" />
    </div>

    <b-row>
      <b-col cols="12" md="7">
        <b-list-group class="heater-runs-list mt-3" cols-md="8" flush>
          <HeaterRunLogEntryControl v-for="runLogEntry in heaterRuns" v-bind:key="runLogEntry.startedAt" :run-log-entry="runLogEntry"/>
        </b-list-group>
      </b-col>
    </b-row>

  </b-container>
</template>


<script>
import RefreshButton from "@/components/common/RefreshButton";
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";
import HeaterRunLogEntryControl from "@/components/heating/HeaterRunLogEntryControl";

export default {
  name: 'HeatingPage',
  components: {
    RefreshButton,
    HeaterRunLogEntryControl,
  },

  data: function() {
    return {
      loading: true,
      heaterRuns: null,
    }
  },

  created() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.loading = true;
      axios.get('/api/heating/log')
        .then( response => this.heaterRuns = response.data.heaterRunLog )
        .catch( error => showAxiosErrorToast("Failed to fetch heater runs", error) )
        .finally( () => this.loading = false )
    },
  }

}
</script>
