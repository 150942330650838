<template>
  <b-form-row class="align-items-center">
    <b-col cols="2"  lg="1"><label class="col-form-label">Agent:</label></b-col>
    <b-col cols="10" lg="11">
      <b-form-select :value="value" @change="onChange($event)">
        <b-form-select-option v-for="agent in agentsList" :value="agent.id" :key="agent.id">
          {{agent.id}} - {{agent.description}} ({{agent.locationDisplayName}})
        </b-form-select-option>
      </b-form-select>
    </b-col>
  </b-form-row>
</template>


<script>
export default {
  name: 'AgentsSelect',

  props: {
    agentsList: Array,
    value: String,
  },

  methods: {
    onChange: function(event) {
      this.$emit('input', event);
      this.$emit('change');
    }
  },

}
</script>