<template>
  <div>
    <div class="d-flex gap-1 align-items-center">
      <h2>Commands</h2>
      <RefreshButton variant="outline-success" :loading="loading" @click="reloadCommandsList" />
    </div>

    <b-overlay :show="loading" rounded="sm" spinner-variant="primary" spinner-type="grow">
      <b-table-lite
          striped hover small responsive
          head-variant="dark"
          :items="commandsList"
          :fields="tableFields"
          class="mt-2"
      >
        <template #cell(status)="data">
          <div v-b-tooltip.hover :title="data.item | statusTooltip" >
            <BIconChatSquareDots  v-if="data.value === statuses.Creating" variant="secondary" font-scale="1.3" />
            <BIconChatSquareDots  v-else-if="data.value === statuses.Created" variant="success" font-scale="1.3" />
            <BIconViewStacked  v-else-if="data.value === statuses.Enqueued" variant="warning" font-scale="1.3" />
            <BIconClockHistory  v-else-if="data.value === statuses.Expired" variant="secondary" font-scale="1.3" />
            <BIconFiles  v-else-if="data.value === statuses.Duplicated" variant="secondary" font-scale="1.3" />
            <BIconCursor  v-else-if="data.value === statuses.Sent" variant="success" font-scale="1.3" />
            <BIconEnvelope  v-else-if="data.value === statuses.Received" variant="success" font-scale="1.3" />
            <BIconExclamationCircleFill  v-else-if="data.value === statuses.Failed" variant="danger" font-scale="1.3" />
            <BIconInfoCircle  v-else-if="data.value === statuses.ExecutionStarted" variant="success" font-scale="1.3" />
            <BIconCheckCircleFill  v-else-if="data.value === statuses.Executed" variant="success" font-scale="1.3" />
          </div>
        </template>

        <template #cell(target)="data">
          <div v-b-tooltip.hover :title="data.item.targetDeviceName" >
            {{ data.item.targetDeviceId  }}
          </div>
        </template>

        <template #cell(cmdName)="data">
          <div v-b-tooltip.hover :title="data.item.cmdClass" >
            {{ data.value  }}
          </div>
        </template>

        <template #cell(cmdJson)="data">
          <VueJsonPretty :data="data.item.cmdPayload | toJsonObject" />
        </template>

        <template #cell(updated)="data">
          <div v-b-tooltip.hover :title="data.item | createdOrUpdatedTooltip" >
            {{ data.item | createdOrUpdatedIntervalPretty }}
          </div>
        </template>

      </b-table-lite>
    </b-overlay>
  </div>
</template>

<script>
import RefreshButton from "@/components/common/RefreshButton";
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";
import {DateTime} from "luxon";
import {timeIntervalFromPretty} from "@/util/datetime";
import {
  BIconChatSquareDots, BIconCheckCircleFill, BIconClockHistory,
  BIconCursor,
  BIconEnvelope, BIconExclamationCircleFill,
  BIconFiles, BIconInfoCircle, BIconViewStacked
} from "bootstrap-vue";

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'CommandsListControl',

  components: {
    RefreshButton,
    VueJsonPretty,

    BIconChatSquareDots,
    BIconViewStacked,
    BIconClockHistory,
    BIconFiles,
    BIconCursor,
    BIconEnvelope,
    BIconExclamationCircleFill,
    BIconInfoCircle,
    BIconCheckCircleFill,
  },

  created: function() {
    this.tableFields = [
      { key: 'status', label: '' },
      { key: 'id', label: 'ID' },
      { key: 'target', label: 'Target' },
      { key: 'cmdName', label: 'Name' },
      { key: 'cmdJson', label: 'Payload' },
      { key: 'triggeredBy', label: 'Trigger' },
      { key: 'updated', label: 'Updated' },
    ];

    this.statuses = {
      Creating: 'Creating',
      Created: 'Created',
      Enqueued: 'Enqueued',
      Expired: 'Expired',
      Duplicated: 'Duplicated',
      Sent: 'Sent',
      Received: 'Received',
      Failed: 'Failed',
      ExecutionStarted: 'ExecutionStarted',
      Executed: 'Executed',
    }

    this.reloadCommandsList();
  },

  data: function() {
    return {
      loading: false,
      commandsList: [],
    }
  },

  methods: {
    reloadCommandsList: function() {
      this.loading = true;
      axios.get('/api/commands/history')
          .then(response => this.commandsList = response.data.commands)
          .catch(error => showAxiosErrorToast("Failed to load commands history", error))
          .finally(() => this.loading = false);
    },
  },

  filters: {
    createdOrUpdatedIntervalPretty: function(value) {
      if (value) {
        let timestamp = value.updatedAt || value.createdAt;
        return timeIntervalFromPretty(DateTime.fromSeconds(timestamp));
      }
    },

    createdOrUpdatedTooltip: function(value) {
      if (value) {
        let tooltip = 'Created: ' + DateTime.fromSeconds(value.createdAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
        if (value.updatedAt) {
          tooltip += '\nUpdated: ' + DateTime.fromSeconds(value.updatedAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
        }
        return tooltip;
      }
    },

    statusTooltip: function(value) {
      if (value) {
        let tooltip = value.status;
        if (value.extraInfo && value.extraInfo.trim().length > 0) {
          tooltip += "\n(" + value.extraInfo + ")"
        }
        return tooltip;
      }
    },

    toJsonObject: function(value) {
      if (value) {
        try {
          return JSON.parse(value);
        }
        catch {
          // if failed to parse it (malformed json?), return raw string -
          // it will be displayed correctly (as a string) then
          return value;
        }
      }
    },
  },

}
</script>


<style scoped>
.gap-1 {
  gap: 1em;
}
</style>