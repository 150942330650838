<template>
  <b-container class="container mt-2">
    <h1>Zigbee</h1>
    <ZclDataRequestModal :network-nodes="zigbeeNetworkNodes.nodes"/>
    <div class="mb-3" />
    <ZigbeeNodesControl :network-nodes="zigbeeNetworkNodes" :loading="networkNodesLoading" @reload-network-nodes="refreshNodes" />
    <ZigbeeNetworkTopologyControl />
    <ZigbeeRecentFramesControl :frames="zigbeeFrames" :loading="framesLoading" @reload-frames="refreshFrames"/>
  </b-container>
</template>


<script>
import ZigbeeNodesControl from "@/components/zigbee/ZigbeeNodesControl";
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";
import ZigbeeRecentFramesControl from "@/components/zigbee/ZigbeeRecentFramesControl";
import ZigbeeNetworkTopologyControl from "@/components/zigbee/ZigbeeNetworkTopologyControl";
import ZclDataRequestModal from "@/components/zigbee/ZclDataRequestModal";

export default {
  name: 'ZigbeePage',
  components: {
    ZigbeeNodesControl,
    ZigbeeNetworkTopologyControl,
    ZigbeeRecentFramesControl,
    ZclDataRequestModal,
  },

  data: function() {
    return {
      zigbeeNetworkNodes: {},
      networkNodesLoading: true,

      zigbeeFrames: {},
      framesLoading: true,
    }
  },

  created: function() {
    this.refreshNodes();
    this.refreshFrames();
  },

  methods: {
    refreshNodes: function() {
      this.networkNodesLoading = true;
      axios.get('/api/zigbee/network_nodes')
        .then(response => {
          this.zigbeeNetworkNodes = response.data;
        })
        .catch(error => {
          showAxiosErrorToast("Failed to fetch zigbee network nodes", error)
        })
        .finally(() => {
          this.networkNodesLoading = false;
        });
    },

    refreshFrames: function() {
      this.framesLoading = true;

      axios.get('/api/zigbee/frames')
        .then(response => {
          this.zigbeeFrames = response.data;
        })
        .catch(error => {
          showAxiosErrorToast("Failed to fetch recent zigbee frames", error)
        })
        .finally(() => {
          this.framesLoading = false;
        });
    },
  },
}
</script>