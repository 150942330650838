<template>
  <div>
    <b-form-row class="align-items-center">
      <b-col cols="6" md="5" lg="3" xl="2"><label class="col-form-label">Settling day:</label></b-col>
      <b-col cols="auto"><b-form-input type="number" min="1" max="31" v-model="settlingDay"/></b-col>
    </b-form-row>
    <b-form-row class="align-items-center">
      <b-col cols="6" md="5" lg="3" xl="2"><label class="col-form-label">Number of months:</label></b-col>
      <b-col cols="auto"><b-form-input type="number" min="1" max="60" v-model="numberOfMonths"/></b-col>
    </b-form-row>
    <RefreshButton variant="outline-success" default-label="Generate report" loading-label="Generating" no-default-icon
                   :loading="reportGenerating" @click="generateRangeReport"/>

    <PeriodReportTableControl unit="Month" :report="report" :busy="reportGenerating" class="mt-3"/>

  </div>
</template>


<script>
import RefreshButton from "@/components/common/RefreshButton";
import PeriodReportTableControl from "@/components/meters/PeriodReportTableControl";
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";

export default {
  name: 'MonthlyReportTab',

  components: {
    RefreshButton,
    PeriodReportTableControl,
  },

  data: function() {
    return {
      settlingDay: 1,
      numberOfMonths: 12,
      reportGenerating: false,
      report: null,
    }
  },

  methods: {
    generateRangeReport: function() {
      this.reportGenerating = true;
      let queryParams = {
        monthsBack: this.numberOfMonths,
        settlingDay: this.settlingDay,
      }
      axios.get('/api/meters/reports/monthly', { params: queryParams })
          .then( response => this.report = response.data)
          .catch( error => showAxiosErrorToast("Failed to generate monthly report", error) )
          .finally( () => this.reportGenerating = false )
    }
  }
}
</script>