<template>
  <b-card
      :border-variant="upToDate? 'success' : 'danger'"
      :header-border-variant="upToDate? 'success' : 'danger'"
      class="boiler-status-card">
    <template #header>
      <h5 class="mb-0">Boiler state</h5>
      <small>{{lastUpdate}}</small>
    </template>

    <div class="row ml-0 mr-0">
      <div>
        <b-badge :variant="boilerOnline? 'success' : 'danger'" class="boiler-status-badge">{{ boilerOnline? "Online" : "Offline" }}</b-badge><br>
        <b-badge :variant="heatingModeVariant" class="boiler-status-badge">{{heatingMode}}</b-badge><br>
        <b-badge :variant="fault? 'danger' : 'secondary'" class="boiler-status-badge">Fault</b-badge><br>
        <b-badge :variant="diagnostic? 'danger' : 'secondary'" class="boiler-status-badge">Diagnostic</b-badge><br>
      </div>
      <div class="mr-auto"></div>
      <div>
        <b-badge :variant="centralHeating? 'success' : 'secondary'" class="boiler-status-badge">Heating</b-badge><br>
        <b-badge :variant="hotWater? 'success' : 'secondary'" class="boiler-status-badge">Hot Water</b-badge><br>
        <b-badge :variant="flame? 'warning' : 'secondary'" class="boiler-status-badge">Flame</b-badge><br>
        <b-badge :variant="cooling? 'info' : 'secondary'" class="boiler-status-badge">Cooling</b-badge><br>
      </div>
    </div>

  </b-card>
</template>


<script>
export default {
  name: 'BoilerStateControl',

  props: {
    boilerOnline: Boolean,
    heatingMode: String,

    centralHeating: Boolean,
    hotWater: Boolean,
    cooling: Boolean,
    flame: Boolean,
    fault: Boolean,
    diagnostic: Boolean,

    lastUpdate: String,
    upToDate: Boolean
  },

  computed: {
    heatingModeVariant: function() {
      switch (this.heatingMode) {
        case 'Manual':
          return 'secondary';
        case 'Unknown':
          return 'danger';
        default:
          return 'warning';
      }
    }
  },
}
</script>


<style scoped>
  .boiler-status-card {
    width: 13rem;
  }

  .boiler-status-badge {
    width: 100%;
  }

  small {
    font-size: 0.9em;
  }
</style>