<template>
  <b-container class="container mt-2">
    <h1>Smart meter</h1>

    Current load: <b-badge class="consumption-badge" :variant="currentLoadIndicatorVariant">{{ currentLoadKw | toPrettyDouble }} <small>kWh</small></b-badge>

    <div class="line-chart mt-3">
      <LineChart
          id="chart-electro"
          :options="energyChartOptions"
          :data="energyChartData"
          :height="250"
      />
    </div>

    <div class="line-chart mt-3">
      <LineChart
          id="chart-gas"
          :options="gasChartOptions"
          :data="gasChartData"
          :height="250"
      />
    </div>

    <b-row class="mt-4">
      <b-col cols="auto">
        <b-card header="Readings" class="mt-1" style="width: 18em;">
          <b-overlay :show="!lastReading" rounded="sm" spinner-variant="primary" spinner-type="grow">
            <b-table-simple small borderless v-if="lastReading">
              <tr>
                <td>Energy - high</td>
                <td><b>{{ lastReading.highTariffReading.toFixed(3) }}</b> <small>kWh</small></td>
              </tr>
              <tr>
                <td>Energy - low</td>
                <td><b>{{ lastReading.lowTariffReading.toFixed(3) }}</b> <small>kWh</small></td>
              </tr>
              <tr>
                <td>Current load</td>
                <td><b>{{ lastReading.currentPowerKw.toFixed(3) }}</b> <small>kW</small></td>
              </tr>
              <tr>
                <td>Current tariff</td>
                <td><b>{{ lastReading.currentTariffIndicator }}</b></td>
              </tr>
              <tr>
                <td>Voltage</td>
                <td><b>{{ lastReading.voltage.toFixed(1) }}</b> <small>V</small></td>
              </tr>
              <tr>
                <td>Gas reading</td>
                <td><b>{{ lastReading.gasReadingValue.toFixed(3) }}</b> <small>m³</small></td>
              </tr>
            </b-table-simple>
          </b-overlay>
        </b-card>
      </b-col>

      <b-col cols="auto">
        <b-card header="Failures" class="mt-1" style="width: 23em;">
          <b-overlay :show="!lastReading" rounded="sm" spinner-variant="primary" spinner-type="grow">
            <b-table-simple small borderless v-if="lastReading">
              <tr>
                <td>Power failures</td>
                <td><b>{{ lastReading.powerFailuresCount }}</b></td>
              </tr>
              <tr>
                <td>Long power failures</td>
                <td><b>{{ lastReading.longPowerFailuresCount }}</b></td>
              </tr>
              <tr>
                <td>Voltage sags</td>
                <td><b>{{ lastReading.voltageSagsCount }}</b></td>
              </tr>
              <tr>
                <td>Voltage swells</td>
                <td><b>{{ lastReading.voltageSwellsCount }}</b></td>
              </tr>
              <tr>
                <td>Last power failure</td>
                <td v-if="lastPowerFailure">
                  <b>{{ lastPowerFailure.durationSeconds | toPrettyInterval }}</b><br>
                  {{ lastPowerFailure.start | toLocalDateTimeShort }} - <br>
                  {{ lastPowerFailure.end | toLocalDateTimeShort }}
                </td>
              </tr>
            </b-table-simple>
          </b-overlay>
        </b-card>
      </b-col>

      <b-col cols="auto">
        <b-card header="Timestamps" class="mt-1" style="width: 21em;">
          <b-overlay :show="!lastReading" rounded="sm" spinner-variant="primary" spinner-type="grow">
            <b-table-simple small borderless v-if="lastReading">
              <tr>
                <td>Entry timestamp</td>
                <td>{{ lastReading.timestamp | toLocalDateTime }}</td>
              </tr>
              <tr>
                <td>Smart meter timestamp</td>
                <td>{{ lastReading.deviceTimestamp | toLocalDateTime }}</td>
              </tr>
              <tr>
                <td>Gas reading timestamp</td>
                <td>{{ lastReading.gasReadingTimestamp | toLocalDateTime }}</td>
              </tr>
            </b-table-simple>
          </b-overlay>
        </b-card>
      </b-col>

    </b-row>

  </b-container>
</template>


<script>
import { Line as LineChart } from 'vue-chartjs'
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, TimeScale, CategoryScale, Title, Tooltip } from 'chart.js'
import 'chartjs-adapter-luxon'
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";
import {DateTime} from "luxon";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, TimeScale, CategoryScale, Title, Tooltip)

export default {
  name: 'SmartmeterPage',
  components: {
    LineChart
  },

  data() {
    return {
      mounted: true,
      energyChartItems: null,
      gasChartItems: null,
      currentTimestamp: null,
      currentLoadKw: null,
      currentLoadIndicator: null,
      lastReading: null,
      lastPowerFailure: null,
    }
  },

  computed: {
    energyChartData: function() {
      return {
        datasets: [
          {
            data: this.energyChartItems,
            borderColor: 'rgb(0, 128, 0)',
            pointRadius: 2,
            label: 'power (kW)',
            tension: 0.1,
          }
        ]
      }
    },

    gasChartData: function() {
      return {
        datasets: [
          {
            data: this.gasChartItems,
            borderColor: 'rgb(0, 0, 196)',
            pointRadius: 2,
            label: 'vol (m³/5min)',
            tension: 0.1,
          }
        ]
      }
    },

    energyChartOptions: function() {
      return this.generateChartOptions("Instant electricity consumption", "kW", 1.0)
    },

    gasChartOptions: function() {
      return this.generateChartOptions("Gas consumption (avg per 5 min)", "m³/5min", 0.1)
    },

    currentLoadIndicatorVariant: function() {
      const indicator = this.currentLoadIndicator;
      if (indicator === 'Minimal') {
        return 'secondary';
      } else if (indicator === 'Normal') {
        return 'success';
      } else if (indicator === 'Significant') {
        return 'warning';
      } else if (indicator === 'High') {
        return 'danger';
      } else {
        return 'dark';
      }
    }
  },

  created: function() {
    this.refreshCycle();
  },

  destroyed: function() {
    this.mounted = false;
  },

  methods: {
    refreshCycle: async function() {
      await this.refreshData();
      if (this.mounted) {
        setTimeout(this.refreshCycle, 2000);
      }
    },

    refreshData: function() {
      return axios.get('/api/meters/dashboard')
      .then( response => this.applyFreshData(response.data) )
      .catch( error => showAxiosErrorToast("Failed to fetch smart meter dashboard", error) )
    },

    applyFreshData: function(data) {
      this.currentLoadKw = data.currentLoadKw;
      this.currentTimestamp = data.currentTimestamp;
      this.energyChartItems = data.energyChartData;
      this.gasChartItems = data.gasChartData;
      this.currentLoadIndicator = data.currentLoadIndicator;
      this.lastReading = data.lastReading;
      this.lastPowerFailure = data.lastPowerFailure;
    },

    generateChartOptions: function(chartTitle, yAxeLabel, suggestedMax) {
      return {
        animation: {
          duration: 0
        },
        plugins: {
          title: {
            display: true,
            text: chartTitle
          },
          tooltip: {
            intersect: false,
            mode: 'index'
          },
          legend: {
            display: false,
          }
        },
        scales: {
          x: {
            type: 'time',
            distribution: 'linear',
            max: this.currentTimestamp,
            time: {
              parser: (x) => (x * 1000),
            }
          },
          y: {
            beginAtZero: true,
            suggestedMax: suggestedMax,
            title: {
              display: true,
              text: yAxeLabel,
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },

  filters: {
    toPrettyDouble(value) {
      if (value) {
        return value.toFixed(3);
      } else {
        return '?';
      }
    },

    toLocalDateTime: function(value) {
      if (value) {
        return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      }
    },

    toLocalDateTimeShort: function(value) {
      if (value) {
        return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT);
      }
    },

    toPrettyInterval: function(intervalSeconds) {
      if (intervalSeconds > 60 * 60) {
        return (intervalSeconds / 60 / 60).toFixed(1) + " hour(s)";
      } else if (intervalSeconds > 60) {
        return ((intervalSeconds / 60) | 0) + " minute(s)";
      } else {
        return intervalSeconds + " second(s)";
      }
    },
  }
}
</script>


<style scoped>
.consumption-badge {
  font-size: 1em;
}

.line-chart {
  max-width: 600px;
}
</style>

