<template>
  <b-overlay :show="showSpinner" rounded="sm" spinner-variant="primary" spinner-type="grow">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="mb-0 mr-auto">♨ Heating</h5>
          <b-badge v-if="heatingStatus.heatingOn" pill variant="danger" v-b-tooltip.hover title="Central heating">CH</b-badge>
          <b-badge v-if="heatingStatus.hotWaterOn" pill variant="warning" v-b-tooltip.hover title="Domestic hot water">DHW</b-badge>
          <div class="mr-auto"></div>
          <b-badge v-if="!heatingStatus.controllerOnline" variant="danger">Controller offline</b-badge>
        </div>

        <div class="row">
          <div class="col-10">
            <b-progress
                max="100"  variant="warning" :value="heatingStatus.modulation" height="8px" class="mt-3"
                v-b-tooltip.hover :title="'Modulation: ' + heatingStatus.modulation + '%'"
            />
          </div>
          <span v-if="heatingStatus.flame" class="col-2" v-b-tooltip.hover title="Flame is on">🔥</span>
        </div>
      </template>

      <b-form-select
          :options="availableStrategies"
          v-model="selectedStrategy"
          @change="onStrategyChanged"
      />
      <ManualHeatingParamsControl
          v-if="selectedStrategy === 'Manual'"
          :heating-timeout-minutes.sync="manualHeatingParams.heatingTimeoutMinutes"
          :target-boiler-temperature.sync="manualHeatingParams.targetBoilerTemperature"
          @change="onManualHeatingParamChanged"
      />
      <PeriodicHeatingParamsControl
          v-else-if="selectedStrategy === 'Periodic'"
          :mode.sync="periodicHeatingParams.mode"
          @change="onPeriodicHeatingModeChanged"
      />
    </b-card>
  </b-overlay>
</template>


<script>
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";
import ManualHeatingParamsControl from "@/components/heating/ManualHeatingParamsControl.vue";
import PeriodicHeatingParamsControl from "@/components/heating/PeriodicHeatingParamsControl.vue";

const DefaultTargetTemperature = 40;
const DefaultTimeoutMinutes = 0;

export default {
  name: 'HeatingControl',
  props: {
    heatingStatusFull: Object,
  },
  components: {
    ManualHeatingParamsControl,
    PeriodicHeatingParamsControl,
  },

  data: function() {
    return {
      availableStrategies: [
        {value: 'Disabled', text: 'Heating off'},
        {value: 'Manual', text: 'Manual heating'},
        {value: 'Periodic', text: 'Periodic heating'}
      ],
      selectedStrategy: 'Disabled',

      heatingStatus: {
        controllerOnline: true,
        heatingOn: false,
        hotWaterOn: false,
        modulation: 0,
        flame: false,
      },
      manualHeatingParams: {
        targetBoilerTemperature: DefaultTargetTemperature,
        heatingTimeoutMinutes: DefaultTimeoutMinutes,
      },
      periodicHeatingParams: {
        mode: 'Off'
      },

      postingUpdate: false,
      initialised: false,
    }
  },

  computed: {
    showSpinner: function() {
      return this.postingUpdate || !this.initialised;
    }
  },

  methods: {
    updateStatus: function(heatingStatusResponse) {
      if (!heatingStatusResponse || this.postingUpdate) {
        return
      }
      if (heatingStatusResponse.status) {
        this.heatingStatus = heatingStatusResponse.status;
        this.initialised = true;
      }
      if (heatingStatusResponse.strategy) {
        this.selectedStrategy = heatingStatusResponse.strategy;
        this.initialised = true;
      }
      if (heatingStatusResponse.manualStrategyParams) {
        this.manualHeatingParams = heatingStatusResponse.manualStrategyParams;
      }
      if (heatingStatusResponse.periodicStrategyParams) {
        this.periodicHeatingParams = heatingStatusResponse.periodicStrategyParams;
      }
    },

    processChange: async function() {
      try {
        let requestBody = {};
        if (this.selectedStrategy === 'Manual') {
          requestBody.targetBoilerTemperature = this.manualHeatingParams.targetBoilerTemperature;
          requestBody.heatingTimeoutMinutes = this.manualHeatingParams.heatingTimeoutMinutes;
        } else if (this.selectedStrategy === 'Periodic') {
          requestBody.mode = this.periodicHeatingParams.mode;
        }

        this.postingUpdate = true;
        const response = await axios.post('/api/heating/strategy/' + this.selectedStrategy, requestBody);
        this.updateStatus(response.data.heatingStatus);
      }
      catch(error){
        showAxiosErrorToast("Failed to set heating status", error);
        this.$emit("force-update");
      }
      finally {
        this.postingUpdate = false;
      }
    },

    onStrategyChanged: function() {
      this.processChange();
    },

    onManualHeatingParamChanged: function() {
      this.processChange();
    },

    onPeriodicHeatingModeChanged: function() {
      this.processChange();
    },
  },

  watch: {
    heatingStatusFull: function(){
      this.updateStatus(this.heatingStatusFull);
    },

    selectedStrategy: function() {
      if (this.selectedStrategy === 'Disabled') {
        this.manualHeatingParams.heatingTimeoutMinutes = 0;
        this.periodicHeatingParams.mode = 'Off';
      }
    },
  },
}
</script>


<style scoped>
</style>