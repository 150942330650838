<template>
    <div id="cc-navbar">
      <b-navbar toggleable="lg" type="dark">
        <b-navbar-brand :to="{name: 'Landing'}" class="border border-light rounded p-1 mb-0 h1">CozyCave</b-navbar-brand>

        <div class="header-badges">
          <b-badge v-if="environment !== 'production'" variant="warning">{{ environment }}</b-badge>
        </div>

        <b-navbar-toggle v-show="isLoggedIn" target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Left aligned nav items-->
          <b-navbar-nav v-show="isLoggedIn">
            <b-nav-item :to="{name: 'Dashboard'}">Dashboard</b-nav-item>
            <b-nav-item v-if="grafanaUrl" :href="grafanaUrl" target="_blank">Grafana</b-nav-item>
            <b-nav-item-dropdown id="my-nav-dropdown-meters" text="Meters" toggle-class="nav-link-custom" right>
              <b-dropdown-item :to="{ name: 'SmartmeterPage' }">Realtime</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'MetersReportPage' }">Report</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item :to="{ name: 'HeatingPage' }">Heating</b-nav-item>
            <b-nav-item :to="{ name: 'ClimatePage' }">Climate</b-nav-item>
            <b-nav-item-dropdown id="my-nav-dropdown-meters" text="Camera" toggle-class="nav-link-custom" right>
              <b-dropdown-item :to="{ name: 'CameraPage' }">Take a picture</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'PhotoAlbumPage' }">Photo album</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item :to="{ name: 'MediaStoragePage' }">Media storage</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown id="my-nav-dropdown-meters" text="System" toggle-class="nav-link-custom" right>
              <b-dropdown-item :to="{ name: 'AgentsPage' }">Agents</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'ZigbeePage' }">Zigbee</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'EventsPage' }">Events</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'OpenThermPage' }">OpenTherm</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'SystemMetricsPage' }">Metrics</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'SystemInfoPage' }">Info</b-dropdown-item>
            </b-nav-item-dropdown>

          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav v-show="isLoggedIn" class="ml-auto">
            <b-nav-item-dropdown id="my-nav-dropdown-meters" :text="'👤 ' + displayName" toggle-class="nav-link-custom" right>
              <b-dropdown-item @click="doLogOut">Log out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
          
      </b-navbar>
    </div>
</template>


<script>
import Vue from 'vue'
import { NavbarPlugin } from 'bootstrap-vue'
import { useUserStore } from '@/stores/user.js';
import { useGlobalConfigStore } from "@/stores/global_config";
import { mapState } from 'pinia';
Vue.use(NavbarPlugin)

export default {
  name: 'PageHeader',

  props: {
    environment: String,
  },

  methods: {
    doLogOut: function() {
      const userStore = useUserStore();
      userStore.logout()
          .finally(() => {
            this.$router.push({ path: '/login', query: { redirect_to: this.$router.currentRoute.fullPath } });
          })
    }
  },

  computed: {
    ...mapState(useUserStore, ['displayName', 'isLoggedIn']),
    ...mapState(useGlobalConfigStore, ['grafanaUrl']),
  }
}
</script>


<style scoped>
#cc-navbar {
  background-color: #5c0880;
}

.header-badges .badge {
  margin-right: 5px;
}

</style>