<template>
  <b-overlay :show="showSpinner" rounded="sm" spinner-variant="primary" spinner-type="grow">
    <b-card>

      <template #header>
          <h5 class="mb-0 mr-auto">🌀 Ventilation</h5>
      </template>

      <b-form-checkbox
          switch
          v-model="ventilationOn" @change="processChange">
        Ventilation
      </b-form-checkbox>

      <div class="mt-2">
        <label for="range-timeout" class="mb-0">Timeout</label>
        <b-row class="">
          <b-col cols="9">
            <b-form-input id="range-timeout"
                          v-model.number="minutesRemaining" @change="processTimeoutChange"
                          type="range" min="5" max="90" step="1"></b-form-input>
          </b-col>
          <b-col cols="3" class="pl-0 pr-0">
            {{ minutesRemaining }} min
          </b-col>
        </b-row>
      </div>

    </b-card>
  </b-overlay>
</template>


<script>
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";

const DefaultTimeoutMinutes = 30;

export default {
  name: 'VentilationControl',
  props: {
    ventilationStatus: Object,
  },

  data: function() {
    return {
      postingUpdate: false,
      ventilationOn: false,
      minutesRemaining: DefaultTimeoutMinutes,
    }
  },

  computed: {
    showSpinner: function() {
      return this.postingUpdate || !this.ventilationStatus;
    }
  },

  watch: {
    ventilationStatus() {
      this.updateState(this.ventilationStatus);
    }
  },

  methods: {
    updateState(ventilationStatus) {
      if (this.postingUpdate || !ventilationStatus) {
        return;
      }
      if (this.ventilationOn && !ventilationStatus.ventilationOn) {
        this.minutesRemaining = DefaultTimeoutMinutes;
      } else if (ventilationStatus.ventilationOn) {
        this.minutesRemaining = ventilationStatus.minutesRemaining;
      }
      this.ventilationOn = ventilationStatus.ventilationOn;
    },

    processTimeoutChange: async function() {
      if (this.ventilationOn) {
        await this.processChange();
      }
    },

    processChange: async function() {
      this.postingUpdate = true;
      let requestBody = {
        ventilationOn: this.ventilationOn,
      }
      if (requestBody.ventilationOn) {
        requestBody.timeoutMinutes = this.minutesRemaining;
      }
      try {
        await axios.put('/api/ventilation/set', requestBody);
      } catch (error) {
        showAxiosErrorToast("Failed to set ventilation status", error);
        this.$emit("force-update");
      } finally {
        this.postingUpdate = false;
      }
    }
  }
}
</script>


<style scoped>
</style>