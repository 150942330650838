<template>
  <div>
    <div class="d-flex align-items-center gap-1">
      <h2>Zigbee - network</h2>
      <RefreshButton variant="outline-success" :loading="loading" @click="$emit('reload-network-nodes')" />
    </div>

    <b>Last updated:</b> {{networkNodes.updatedAt | toLocaleDateTime}}<br>
    <b>Network snapshot version:</b> {{networkNodes.snapshotVersion}}

    <b-overlay :show="loading" rounded="sm" spinner-variant="primary" spinner-type="grow">
      <b-table-lite striped hover small responsive
                    head-variant="dark"
                    :items="networkNodes.nodes"
                    :fields="tableFields"
                    class="mt-4"
      >
        <template #cell(ieeeAddressPretty)="data">
            {{data.item.ieeeAddress | toPrettyIeeeAddress}}
        </template>

        <template #cell(lastSeen)="data">
          <div v-b-tooltip.hover :title="data.item.lastSeen | toLocaleDateTime" >
            {{data.item.lastSeen | toTimeAgo}}
          </div>
        </template>

        <template #cell(batteryLevel)="data">
          <div v-if="data.item.batteryLevel" v-b-tooltip.hover :title="data.item.batteryLevelUpdatedAt | toLocaleDateTime" >
            {{data.item.batteryLevel}}%
          </div>
        </template>

        <template #cell(registered)="data">
          <b-badge v-if="!data.item.registered" variant="danger">unregistered</b-badge>
        </template>

      </b-table-lite>
    </b-overlay>

  </div>
</template>


<script>
import {DateTime} from "luxon";
import {timeIntervalFromPretty} from "@/util/datetime";
import RefreshButton from "@/components/common/RefreshButton";

export default {
  name: 'ZigbeeNodesControl',
  props: {
    networkNodes: Object,
    loading: Boolean,
  },
  components: {
    RefreshButton,
  },

  data: function() {
    return {
      tableFields: [
        { key: "ieeeAddressPretty", label: "MAC address" },
        { key: "networkAddress", label: "Network address" },
        { key: "deviceType", label: "Device" },
        { key: "description", label: "Description" },
        { key: "location", label: "Location" },
        { key: "lastSeen", label: "Last seen" },
        { key: "batteryLevel", label: "Battery" },
        { key: "registered", label: "" },
      ],
    }
  },


  filters: {
    toPrettyIeeeAddress: function(value) {
      return '0x' + value.padStart(16, '0');
    },

    toPrettyDateTime: function(value) {
      if (value) {
        let localDateTime = DateTime.fromSeconds(value);
        let unitsAgo = timeIntervalFromPretty(localDateTime);
        return unitsAgo + " (" + localDateTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) + ")";
      }
    },

    toTimeAgo: function(value) {
      if (value) {
        return timeIntervalFromPretty(DateTime.fromSeconds(value));
      }
    },

    toLocaleDateTime: function(value) {
      if (value) {
        return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      }
    },
  }
}
</script>


<style scoped>
  .gap-1 {
    gap: 1em;
  }
</style>