<template>
  <b-form-row class="align-items-center">
    <b-col cols="2" lg="1"><label class="col-form-label">{{label}}</label></b-col>
    <b-col cols="auto"><b-form-datepicker v-model="date" :max="maxDate" @input="onChange" /></b-col>
    <b-col cols="auto"><b-form-timepicker v-model="time" @input="onChange" /></b-col>
  </b-form-row>
</template>


<script>


import {DateTime} from "luxon";

export default {
  name: 'DateTimeControl',
  props: {
    label: String,
    defaultTime: String,
    dateTime: Object,
    maxDate: Date,
  },

  data: function() {
    return {
      date: '',
      time: '',
    }
  },

  mounted: function() {
    this.time = this.defaultTime;
  },

  methods: {
    onChange: function() {
      let luxonDateTime = DateTime.fromISO(this.date + 'T' + this.time);
      this.$emit('update:dateTime', luxonDateTime);
    }
  }
}
</script>