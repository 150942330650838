import DashboardPage from '@/components/dashboard/DashboardPage'
import LoginPage from '@/components/login/LoginPage'
import OpenThermPage from '@/components/opentherm/OpenThermPage'
import SystemInfoPage from "@/components/system/SystemInfoPage"
import EventsPage from "@/components/system/EventsPage"
import SystemMetricsPage from "@/components/system/SystemMetricsPage";
import ZigbeePage from "@/components/zigbee/ZigbeePage";
import CameraPage from "@/components/camera/CameraPage";
import MediaStoragePage from "@/components/camera/MediaStoragePage";
import PhotoAlbumPage from "@/components/camera/PhotoAlbumPage";
import AgentsPage from "@/components/agents/AgentsPage";
import MetersReportPage from "@/components/meters/MetersReportPage";
import SmartmeterPage from "@/components/meters/SmartmeterPage";
import ClimatePage from "@/components/climate/ClimatePage";
import HeatingPage from "@/components/heating/HeatingPage";

export const AllRoutes = [
  { path: '/login', component: LoginPage, name: 'Login', meta: { title: "CozyCave - Login" } },
  { path: '/', component: DashboardPage, name: 'Landing', meta: { title: "CozyCave - Dashboard" } },
  { path: '/dashboard', component: DashboardPage, name: 'Dashboard', meta: { title: "CozyCave - Dashboard" } },

  { path: '/smartmeter', component: SmartmeterPage, name: 'SmartmeterPage', meta: { title: "CozyCave - Smartmeter" } },
  { path: '/meters_report', component: MetersReportPage, name: 'MetersReportPage', meta: { title: "CozyCave - Meters report" } },

  { path: '/heating', component: HeatingPage, name: 'HeatingPage', meta: { title: "CozyCave - Heating" } },

  { path: '/climate', component: ClimatePage, name: 'ClimatePage', meta: { title: "CozyCave - Climate" } },

  { path: '/camera', component: CameraPage, name: 'CameraPage', meta: { title: "CozyCave - Camera" } },
  { path: '/photo_album', component: PhotoAlbumPage, name: 'PhotoAlbumPage', meta: { title: "CozyCave - Photo album" } },
  { path: '/media_storage', component: MediaStoragePage, name: 'MediaStoragePage', meta: { title: "CozyCave - Media storage" } },

  { path: '/agents', component: AgentsPage, name: 'AgentsPage', meta: { title: "CozyCave - Agents" } },
  { path: '/zigbee', component: ZigbeePage, name: 'ZigbeePage', meta: { title: "CozyCave - Zigbee" } },
  { path: '/opentherm', component: OpenThermPage, name: 'OpenThermPage', meta: { title: "CozyCave - OpenTherm" } },
  { path: '/events', component: EventsPage, name: 'EventsPage', meta: { title: "CozyCave - Events" } },
  { path: '/system_metrics', component: SystemMetricsPage, name: 'SystemMetricsPage', meta: { title: "CozyCave - System metrics" } },
  { path: '/system_info', component: SystemInfoPage, name: 'SystemInfoPage', meta: { title: "CozyCave - System info" } },
]
