<template>
  <b-modal id="photo-modal" v-model="showModal" centered size="lg" hide-header footer-class="justify-content-center">

    <div v-if="photo">
      <b-img :src="photo.link" alt="photo" fluid/>
      <p class="mb-0">
        <b>{{ photo.location }}, {{ photo.createdAt | toLocalDateTime }},</b>
        {{ photo.reference }} ({{ photo.seq }}) <br>
        <b>requested at </b> {{ photo.requestedAt | toLocalDateTime }} <b>by</b> {{ photo.requestedBy }},
        <b>size:</b> {{ photo.imageSize | toKb }} K, <b>preview:</b> {{ photo.previewSize | toKb }} K
      </p>
    </div>

    <template #modal-footer="{ hide }">
      <b-button variant="primary" @click="previousPhoto">←</b-button>
      <b-button variant="secondary" @click="hide()">Close</b-button>
      <b-button variant="primary" @click="nextPhoto">→</b-button>
    </template>
  </b-modal>
</template>

<script>
import {DateTime} from "luxon";

export default {
  name: 'PhotoModal',

  props: {
    photo: Object,
    currentPhotoIndex: Number,
  },

  data: function() {
    return {
      showModal: false,
    }
  },

  created() {
    window.addEventListener('keydown', this.onKey);
  },

  destroyed() {
    window.removeEventListener('keydown', this.onKey);
  },

  methods: {
    // Despite all the effort, I failed to make it work with @keydown event on b-modal & playing with tabindex
    onKey: function(event) {
      if (this.showModal && event) {
        if (event.which === 37) {
          this.previousPhoto();
        } else if (event.which === 39) {
          this.nextPhoto();
        }
      }
    },

    previousPhoto() {
      this.$emit('previous-photo');
    },

    nextPhoto() {
      this.$emit('next-photo');
    },
  },

  filters: {
    toLocalDateTime: function(value) {
      if (value) {
        return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      }
    },

    toKb: function(value) {
      if (value) {
        return Math.round(value / 1024);
      } else {
        return "?";
      }
    },
  },

}
</script>
