<template>
  <div>
    <div class="d-flex gap-1 align-items-center">
      <h2>Zigbee - recent frames</h2>
      <RefreshButton variant="outline-success" :loading="loading" @click="$emit('reload-frames')" />
    </div>

    <b-overlay :show="loading" rounded="sm" spinner-variant="primary" spinner-type="grow">
      <b-table-lite striped hover small responsive
                    head-variant="dark"
                    :items="frames.frames"
                    :fields="tableFields"
                    class="mt-4"
      >
        <template #cell(time)="data">
          <div v-b-tooltip.hover :title="data.item.timestamp | toLocaleDateTime" >
            {{data.item.timestamp | toLocaleTime}}
          </div>
        </template>

      </b-table-lite>
    </b-overlay>

  </div>
</template>


<script>
import {DateTime} from "luxon";
import RefreshButton from "@/components/common/RefreshButton";

export default {
  name: 'ZigbeeRecentFramesControl',
  props: {
    frames: Object,
    loading: Boolean,
  },
  components: {
    RefreshButton,
  },

  data: function() {
    return {
      tableFields: [
        { key: "time", label: "Time" },
        { key: "agentTimestamp", label: "Agent epoch" },
        { key: "direction", label: "In/Out" },
        { key: "znpSubsystem", label: "Znp Subsys" },
        { key: "znpCommand", label: "Znp Cmd" },
        { key: "group", label: "Group" },
        { key: "cluster", label: "Cluster" },
        { key: "srcDstAddress", label: "Src/dst addr" },
        { key: "endpoint", label: "EP" },
        { key: "make", label: "Make" },
        { key: "zclCommand", label: "Zcl Cmd" },
        { key: "payload", label: "Payload" },
      ],
    }
  },

  filters: {
    toLocaleDateTime: function(value) {
      if (value) {
        return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      }
    },

    toLocaleTime: function(value) {
      if (value) {
        return DateTime.fromSeconds(value).toLocaleString(DateTime.TIME_WITH_SECONDS);
      }
    },
  },
}
</script>


<style scoped>
.gap-1 {
  gap: 1em;
}
</style>