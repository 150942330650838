<template>
  <b-container class="container mt-2">
    <h1>Camera</h1>

    <b-form-row class="align-items-center">
      <b-col cols="auto">Camera:</b-col>
      <b-col cols="auto" md="3">
        <b-form-select :options="cameraDevices" v-model="selectedCamera"/>
      </b-col>
      <b-col>
        <b-button variant="primary" :disabled="busyState != null" @click="makePhoto">
          <section v-if="busyState != null">
            <b-spinner small></b-spinner>
            {{busyState}}...
          </section>
          <section v-else>
            <BIconCamera/>
            Photo
          </section>
        </b-button>
      </b-col>
    </b-form-row>

    <img v-if="imageUrl" :src="imageUrl" class="img-fluid img-thumbnail" alt="Photo">


  </b-container>
</template>


<script>
import {BIconCamera} from "bootstrap-vue";
import axios from "axios";
import {showAxiosErrorToast, showErrorToast} from "@/util/toasts";

const CheckPhotoInterval = 1000;
const FirstCheckAttemptTimeout = 1500;
const MaxAttempts = 10;

export default {
  name: 'CameraPage',

  components: {
    BIconCamera,
  },

  created: function() {
    this.busyState = "Loading"
    axios.get('/api/camera/devices')
      .then( response => {
        this.cameraDevices = response.data.availableCameras.map(x => ({ value: x.deviceId, text: x.locationDisplayName }));
        if (this.cameraDevices && this.cameraDevices.length > 0) {
          this.selectedCamera = this.cameraDevices[0].value;
        }
      })
      .catch( error => showAxiosErrorToast("Failed to fetch available cameras list", error) )
      .finally( () => this.busyState = null)
  },

  data: function() {
    return {
      busyState: "Loading",
      cameraDevices: null,
      selectedCamera: null,
      imageUrl: null,
    }
  },

  methods: {
    makePhoto: function() {
      if (!this.selectedCamera) {
        showErrorToast("No camera selected");
        return;
      }
      this.imageUrl = null;
      this.busyState = "Taking a picture"
      let queryParams = { camId: this.selectedCamera };
      axios.post('/api/camera/request_photo', null, { params: queryParams })
        .then(response => {
          let imageRef = response.data.imageReference;
          console.log("Image reference: " + imageRef);
          this.waitForPhoto(imageRef, queryParams.camId);
        })
        .catch(error => {
          showAxiosErrorToast("Failed to take a picture", error);
          this.busyState = null;
        });
    },

    waitForPhoto: async function(imageReference, deviceId) {
      this.busyState = "Waiting for photo";
      let attempts = 0;
      let mainThis = this;
      let checkPhoto = function() {
        ++attempts;
        let queryParams = { imgRef: imageReference, deviceId: deviceId };
        let headResponse = null;
        axios.head('/api/media_storage', {params: queryParams})
          .then(response => headResponse = response)
          .catch(() => {})
          .finally(() => {
            if (headResponse) {
              mainThis.imageUrl = headResponse.request.responseURL;
              mainThis.busyState = null;
            } else {
              if (attempts >= MaxAttempts) {
                showErrorToast("Failed to take a picture");
                mainThis.busyState = null;
              } else {
                setTimeout(checkPhoto, CheckPhotoInterval);
              }
            }
          });

      };
      setTimeout(checkPhoto, FirstCheckAttemptTimeout);
    }
  },

}
</script>