<template>
  <b-list-group-item class="pt-1 pb-1 pl-0 pr-0 d-flex justify-content-between align-items-center">
      <span class="ml-0">
        <div class="d-inline" v-b-tooltip.hover :title="updatedIcoTooltip">
          <BIconExclamationCircle v-if="icoType === 'VeryOld'" variant="danger"/>
          <BIconQuestionCircle v-if="icoType === 'Old'" variant="warning"/>
          <BIconCheckCircle v-if="icoType === 'Recent'" variant="info" />
          <BIconCheckCircle v-if="icoType === 'JustNow'" variant="success" />
        </div>
        {{ location }}
      </span>
    <span>
      {{ temperature.toFixed(1) }} <small>℃</small>
      <BIconDot />
      {{ (humidity | 0) }} <small>%</small>
    </span>
  </b-list-group-item>
</template>


<script>
import {BIconCheckCircle, BIconDot, BIconExclamationCircle, BIconQuestionCircle} from "bootstrap-vue";
import {DateTime} from "luxon";

export default {
  name: 'ClimateDashboardItemControl',
  components: {
    BIconDot,
    BIconExclamationCircle,
    BIconQuestionCircle,
    BIconCheckCircle,
  },
  props: {
    location: String,
    temperature: Number,
    humidity: Number,
    updatedAtEpoch: Number,
    icoType: String,
  },

  computed: {
    updatedIcoTooltip: function() {
      const updatedAtDateTime = DateTime.fromSeconds(this.updatedAtEpoch);
      const timeDiff = DateTime.now().diff(updatedAtDateTime);
      const minutesAgo = timeDiff.as('minutes') | 0;
      let agoStr;
      if (minutesAgo <= 1) {
        agoStr = "Updated just now";
      } else if (minutesAgo < 120)
        agoStr = "Updated " + minutesAgo + " minutes ago";
      else {
        const hoursAgo = timeDiff.as('hours') | 0;
        if (hoursAgo <= 48) {
          agoStr = "Updated " + hoursAgo + " hours ago";
        } else {
          const daysAgo = timeDiff.as('days') | 0;
          agoStr = "Updated " + daysAgo + " days ago";
        }
      }

     return agoStr + "\n" + updatedAtDateTime.toLocaleString(DateTime.DATETIME_SHORT);
    },
  },

}
</script>