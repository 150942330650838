<template>
  <b-modal id="agent-send-command-modal" v-model="showModal" centered size="lg" title="Send command to agent">

    <AgentsSelect :agents-list="agentsList" v-model="selectedAgent" />

    <label for="inputCommandJson" class="mt-3">Command json:</label>
    <b-form-textarea
        id="inputCommandJson"
        v-model="commandJson"
        placeholder="Command JSON"
        rows="7"
    />

    <template #modal-footer>
      <b-button variant="secondary" @click="showModal=false">Close</b-button>
      <RefreshButton default-label="Send" loading-label="Sending" :loading="sending" no-default-icon @click="sendCommand"/>
    </template>

  </b-modal>
</template>


<script>
import RefreshButton from "@/components/common/RefreshButton";
import axios from "axios";
import {showAxiosErrorToast, showInfoToast} from "@/util/toasts";
import AgentsSelect from "@/components/agents/AgentsSelect";

const DefaultCommandStub = "{\n" +
    "  \"cmd\": \"Buzzer\",\n" +
    "  \"seq\": 42\n" +
    "}";

export default {
  name: 'SendCommandModal',

  components: {
    AgentsSelect,
    RefreshButton,
  },

  props: {
    agentsList: Array,
    clickedAgent: Object,
  },

  data: function() {
    return {
      showModal: false,
      selectedAgent: null,
      commandJson: DefaultCommandStub,
      sending: false,
    }
  },

  methods: {
    sendCommand: function() {
      let postBody = {
        targetDevice: this.selectedAgent,
        customCommandJson: this.commandJson,
      };
      this.sending = true;
      axios.post('/api/device_cmd/custom_cmd', postBody)
        .then( response => showInfoToast("Command sent! Command id: " + response.data.commandId) )
        .catch( error => showAxiosErrorToast("Failed to send command to agent", error) )
        .finally( () => this.sending = false )
    }
  },

  watch: {
    clickedAgent: function() {
      this.selectedAgent = this.clickedAgent.id;
    },
  }
}
</script>