<template>
    <div class="mt-3">
      <label for="range-boiler-temperature" class="mb-0">Mode: {{ modes[modeNum] }}</label>
          <b-form-input id="range-boiler-temperature"
                        v-model.number="modeNum"
                        @change="$emit('update:mode', modes[modeNum]); $emit('change');"
                        type="range" min="0" :max="modes.length - 1" step="1"></b-form-input>
    </div>
</template>

<script>
export default {
  name: 'PeriodicHeatingParamsControl',
  props: {
    mode: String,
  },

  data() {
    return {
      modes: ['Off', 'Low', 'Moderate', 'Medium', 'Intensive', 'High'],
      modeNum: 0,
    }
  },

  watch: {
    mode: {
      handler() {
        let idx = this.modes.indexOf(this.mode);
        this.modeNum = idx < 0? 0 : idx;
      },
      immediate: true,
    }
  },
}
</script>