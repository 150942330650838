<template>
  <b-overlay :show="showOverlay" rounded="sm" spinner-variant="primary" spinner-type="grow">
    <b-card>
      <template #header>
        <h5 class="mb-0">⚙ Preset</h5>
      </template>
      <select id="selectPresetCb" v-model="activePreset" v-on:change="onChange" class="form-control" >
        <option v-for="preset in availablePresets" v-bind:value="preset.id" v-bind:key="preset.id">
          {{ preset.icon + " " + preset.displayName }}
        </option>
      </select>
    </b-card>
  </b-overlay>
</template>


<script>
import axios from 'axios'
import {showAxiosErrorToast} from "@/util/toasts";

export default {
  name: 'PresetsControl',
  props: {
    presets: Object,
  },

  data: function() {
    return {
      activePreset: null,
      availablePresets: null,
      postingUpdate: false,
    }
  },

  computed: {
    showOverlay: function() {
      return this.postingUpdate || this.availablePresets == null;
    }
  },

  methods: {
    updateState: function(freshPresets) {
      if (freshPresets && !this.postingUpdate) {
        this.activePreset = freshPresets.activePreset;
        this.availablePresets = freshPresets.availablePresets;
      }
    },

    onChange: async function() {
      let newPresetKey = this.activePreset
      console.log("activePreset changed: " + newPresetKey);
      this.postingUpdate = true;
      try {
        await axios.put('/api/preset/' + newPresetKey);
      } catch(error){
        showAxiosErrorToast("Failed to update active preset", error);
        this.$emit("force-update");
      } finally {
        this.postingUpdate = false;
      }
    }
  },

  watch: {
    presets: function() {
      this.updateState(this.presets);
    }
  },
}
</script>


<style scoped>
</style>