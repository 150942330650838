<template>
  <div>
    <SendCommandModal :agents-list="agentsList" :clicked-agent="selectedAgent"/>
    <SendConfigModal :agents-list="agentsList" :clicked-agent="selectedAgent"/>
    <DeviceMetricsModal :agents-list="agentsList" :clicked-agent="selectedAgent"/>
    <DeviceLogsModal :agents-list="agentsList" :clicked-agent="selectedAgent"/>

    <div class="d-flex gap-1 align-items-center">
      <h2>Agents</h2>
      <RefreshButton variant="outline-success" :loading="loading" @click="reloadAgentsList" />
    </div>

    <b-overlay :show="loading" rounded="sm" spinner-variant="primary" spinner-type="grow">
      <b-table-lite
          striped hover small responsive
          head-variant="dark"
          :items="agentsList"
          :fields="tableFields"
          class="mt-2"
      >

        <template #cell(lastSeenIndicator)="data">
          <BIconExclamationCircle v-if="data.value === Offline" variant="danger" font-scale="1.3" />
          <BIconQuestionCircle v-else-if="data.value === LongTimeNotSeen" variant="warning" font-scale="1.3" />
          <BIconCheckCircle v-else-if="data.value === SeenRecently" variant="info" font-scale="1.3" />
          <BIconCheckCircle v-else-if="data.value === SeenJustNow" variant="success" font-scale="1.3" />
          <BIconQuestionCircle v-else-if="data.value === NoData" variant="secondary" font-scale="1.3" />
        </template>

        <template #cell(capabilities)="data">
          {{ data.value.join(", ") }}
        </template>

        <template #cell(lastSeenTimestamp)="data">
          <div v-b-tooltip.hover :title="data.value | toLocaleDateTime" >
            {{ data.value | toIntervalFromPretty }}
          </div>
        </template>

        <template #cell(action)="data">
          <b-dropdown size="sm" variant="outline-info">
            <b-dropdown-item @click="sendCommand(data.item)">Send command</b-dropdown-item>
            <b-dropdown-item @click="sendConfig(data.item)">Send config</b-dropdown-item>
            <b-dropdown-item @click="showDeviceMetrics(data.item)">Device metrics</b-dropdown-item>
            <b-dropdown-item @click="showDeviceLogs(data.item)">Device logs</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="sendPredefinedCommand(data.item, 'buzzer', 'Buzzer sound')">Make buzzer sound</b-dropdown-item>
            <b-dropdown-item @click="sendPredefinedCommand(data.item, 'led_blink', 'Led blink')">Blink with LED</b-dropdown-item>
            <b-dropdown-item @click="sendPredefinedCommand(data.item, 'restart', 'Restart')">Restart</b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table-lite>
    </b-overlay>
  </div>
</template>


<script>
import axios from "axios";
import {showAxiosErrorToast, showInfoToast} from "@/util/toasts";
import {BIconCheckCircle, BIconExclamationCircle, BIconQuestionCircle} from "bootstrap-vue";
import RefreshButton from "@/components/common/RefreshButton";
import {DateTime} from "luxon";
import {timeIntervalFromPretty} from "@/util/datetime";
import SendCommandModal from "@/components/agents/SendCommandModal";
import SendConfigModal from "@/components/agents/SendConfigModal";
import DeviceLogsModal from "@/components/agents/DeviceLogsModal";
import DeviceMetricsModal from "@/components/agents/DeviceMetricsModal";

export default {
  name: 'AgentsListControl',
  components: {
    SendCommandModal,
    SendConfigModal,
    DeviceMetricsModal,
    DeviceLogsModal,

    RefreshButton,

    BIconExclamationCircle,
    BIconQuestionCircle,
    BIconCheckCircle,
  },

  data: function() {
    return {
      loading: true,
      agentsList: [],
      selectedAgent: null,

      tableFields: [
        {key: 'lastSeenIndicator', label: '', tdClass: 'align-middle'},
        {key: 'id', label: 'ID'},
        {key: 'description', label: 'Description'},
        {key: 'locationDisplayName', label: 'Location'},
        {key: 'capabilities', label: 'Capabilities'},
        {key: 'lastSeenTimestamp', label: 'Last seen'},
        {key: 'action', label: 'Action'},
      ],
    }
  },

  created: function() {
    this.SeenJustNow = 'SeenJustNow';
    this.SeenRecently = 'SeenRecently';
    this.LongTimeNotSeen = 'LongTimeNotSeen';
    this.Offline = 'Offline';
    this.NoData = 'NoData';

    this.reloadAgentsList();
  },

  methods: {
    reloadAgentsList: function() {
      this.loading = true;
      axios.get('/api/agent/list')
        .then(response => this.agentsList = response.data.agents)
        .catch(error => showAxiosErrorToast("Failed to load agents list", error))
        .finally(() => this.loading = false);
    },

    sendCommand: function(agent) {
      this.showDialog(agent, 'agent-send-command-modal');
    },

    sendConfig: function(agent) {
      this.showDialog(agent, 'agent-send-config-modal');
    },

    showDeviceMetrics: function(agent) {
      this.showDialog(agent, 'device-metrics-modal');
    },

    showDeviceLogs: function(agent) {
      this.showDialog(agent, 'device-logs-modal');
    },

    showDialog(agent, modalName) {
      this.selectedAgent = agent;
      // nextTick required to make sure modal already sees selectedAgent change
      this.$nextTick( () => this.$bvModal.show(modalName) );
    },

    sendPredefinedCommand(agent, commandName, commandDisplayName) {
      this.loading = true;
      let postBody = {
        targetDevice: agent.id,
      };
      axios.post('/api/device_cmd/' + commandName, postBody)
          .then(response => showInfoToast("'" + commandDisplayName + "' command sent! Command id: " + response.data.commandId))
          .catch(error => showAxiosErrorToast("Failed to send '" + commandDisplayName + "' command", error))
          .finally(() => this.loading = false);
    },
  },

  filters: {
    toLocaleDateTime: function(value) {
      if (value) {
        return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      }
    },

    toIntervalFromPretty: function(value) {
      if (value) {
        return timeIntervalFromPretty(DateTime.fromSeconds(value));
      }
    },
  }
}
</script>


<style scoped>
.gap-1 {
  gap: 1em;
}
</style>