<template>
  <b-container class="container mt-2">
    <div class="d-block position-fixed h-100 w-100 top-0 left-0 z-index-background">
      <div class="container flex-column text-center d-flex justify-content-center align-items-center h-100">

          <div class="row">
            <form id="loginForm" autocomplete="on">
              <p class="h3 mb-3">Welcome to CozyCave!</p>
              <input v-model="username" @keydown="clearError"
                     type="text" class="form-control mb-2" placeholder="User name" autocomplete="username">
              <input v-model="password" @keydown="clearError"
                     type="password" class="form-control mb-2" placeholder="Password" autocomplete="current-password">
              <button v-on:click.prevent="doLogin" type="submit" class="btn btn-outline-success btn-block mb-2">Login</button>
            </form>
          </div>

          <div class="row">
            <div :style="{visibility: (error !== null) ? 'visible' : 'hidden'}" class="alert alert-danger" role="alert" id="alertError">{{ error }}</div>
          </div>

        </div>
      </div>
  </b-container>
</template>


<script>
import {useUserStore} from "@/stores/user";

export default {
  name: 'LoginPage',

  components: {
  },

  data: function() {
    return {
      username: null,
      password: null,
      error: null
    }
  },

  methods: {
    doLogin() {
      this.clearError();
      if (isBlank(this.username) || isBlank(this.password)) {
        this.error = "Please enter your credentials";
        return;
      }
      const userStore = useUserStore();
      userStore.login(this.username, this.password)
        .then(() => {
          // browser saves session cookie automatically in case of successful login

          let redirectTo = this.$router.currentRoute.query['redirect_to'] || '/';
          this.$router.replace({ path: redirectTo });
        })
        .catch(err => {
          this.error = err.response.status === 401? "Invalid credentials" : err.message;
        });
    },

    clearError() {
      this.error = null;
    }
  }

}

function isBlank(s) {
  return s == null || s.trim() === '';
}

</script>


<style scoped>
  .top-0 {
    top: 0
  }

  .left-0 {
    left: 0
  }

  .z-index-background {
    z-index: -1;
  }
</style>