// These values do not change frequently.
// If at some point there will be some active development of
// zigbee features, it might make sense to fetch them from server
// (otherwise every time we add a new command we should do it in
// 2 places).

export const ZigbeeClusters = [
  { value: { key: "Basic", id: 0 }, text: "Basic (0)" },
  { value: { key: "PowerConfig", id: 1 }, text: "PowerConfig (1)" },
  { value: { key: "DeviceTemperatureConfig", id: 2 }, text: "DeviceTemperatureConfig (2)" },
  { value: { key: "Identify", id: 3 }, text: "Identify (3)" },
  { value: { key: "Groups", id: 4 }, text: "Groups (4)" },
  { value: { key: "Scenes", id: 5 }, text: "Scenes (5)" },
  { value: { key: "OnOff", id: 6 }, text: "OnOff (6)" },
  { value: { key: "OnOffSwitchConfig", id: 7 }, text: "OnOffSwitchConfig (7)" },
  { value: { key: "LevelControl", id: 8 }, text: "LevelControl (8)" },
  { value: { key: "Alarms", id: 9 }, text: "Alarms (9)" },
  { value: { key: "Ota", id: 25 }, text: "Ota (25)" },
  { value: { key: "ColourControl", id: 768 }, text: "ColourControl (768)" },
  { value: { key: "TemperatureMeasurement", id: 1026 }, text: "TemperatureMeasurement (1026)" },
  { value: { key: "RelativeHumidity", id: 1029 }, text: "RelativeHumidity (1029)" },
  { value: { key: "PressureMeasurement", id: 1027 }, text: "PressureMeasurement (1027)" },
]

export const ZigbeeCommands = {
  "General": [
    { value: 0, text: "ReadAttributesRequest (0)" },
    { value: 1, text: "ReadAttributesResponse (1)" },
    { value: 10, text: "ReportAttributes (10)" },
    { value: 11, text: "DefaultResponse (11)" },
  ],
  "OnOff": [
    { value: 0, text: "Off (0)" },
    { value: 1, text: "On (1)" },
    { value: 2, text: "Toggle (2)" },
    { value: 66, text: "OnWithTimedOff (66)" },
  ],
  "Scenes": [
    { value: 7, text: "TradfriArrowSingle (7)" },
    { value: 8, text: "TradfriArrowHold (8)" },
    { value: 9, text: "TradfriArrowRelease (9)" },
  ],
  "LevelControl": [
    { value: 0, text: "MoveToLevel (0)" },
    { value: 1, text: "Move (1)" },
    { value: 2, text: "Step (2)" },
    { value: 3, text: "Stop (3)" },
    { value: 4, text: "MoveToLevelOnOff (4)" },
    { value: 5, text: "MoveOnOff (5)" },
    { value: 6, text: "StepOnOff (6)" },
    { value: 7, text: "StopOnOff (7)" },
  ],
  "ColourControl": [
    { value: 7, text: "MoveToColor (7)" },
    { value: 8, text: "MoveColor (8)" },
    { value: 9, text: "StepColor (9)" },
  ],
}