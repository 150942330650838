<template>
  <b-container class="container mt-2">
    <h1>Media storage</h1>
    <RefreshButton :loading="loading" @click="reloadData" class="mb-3"/>

    <h2>Media requests</h2>
    <b-table-lite small hover striped responsive head-variant="" table-class="w-auto"
                  :fields="mediaRequestsTableFields"
                  :items="mediaRequests"
    >
      <template #cell(requestedAt)="data">
        {{ data.value | toLocalDateTime }}
      </template>
    </b-table-lite>

    <h2>Photos</h2>
    <b-table-lite small hover striped responsive head-variant="" table-class="w-auto"
                  :fields="photosTableFields"
                  :items="photos"
    >
      <template #cell(requestedAt)="data">
        {{ data.value | toLocalDateTime }}
      </template>
      <template #cell(createdAt)="data">
        {{ data.value | toLocalDateTime }}
      </template>
      <template #cell(size)="data">
        {{ data.item.imageSize }} / {{ data.item.previewSize || 0 }}
      </template>
      <template #cell(link)="data">
        <a target="_blank" rel="noopener noreferrer" :href="data.value">link</a>
      </template>
    </b-table-lite>

  </b-container>
</template>


<script>
  import RefreshButton from "@/components/common/RefreshButton";
  import axios from "axios";
  import {showAxiosErrorToast} from "@/util/toasts";
  import {DateTime} from "luxon";

  export default {
    name: 'MediaStoragePage',
    components: {
      RefreshButton,
    },

    data: function() {
      return {
        loading: true,
        mediaRequests: null,
        photos: null,

        mediaRequestsTableFields: [
          { key: 'reference', label: 'Reference' },
          { key: 'requestedAt', label: 'Requested at' },
          { key: 'requestedBy', label: 'Requester' },
        ],
        photosTableFields: [
          { key: 'reference', label: 'Reference' },
          { key: 'seq', label: 'Seq' },
          { key: 'requestedBy', label: 'Requester' },
          { key: 'requestedAt', label: 'Requested at' },
          { key: 'createdAt', label: 'Created at' },
          { key: 'location', label: 'Location' },
          { key: 'size', label: 'Size' },
          { key: 'link', label: '' },
        ],
      }
    },

    created: function() {
      this.reloadData();
    },

    methods: {
      reloadData: function() {
        this.loading = true;
        let endpoints = ['/api/media_storage/media_requests', '/api/media_storage/photos'];
        axios
            .all(endpoints.map((endpoint) => axios.get(endpoint)))
            .then(responses => {
              this.mediaRequests = responses[0].data.mediaRequests;
              this.photos = responses[1].data.photos
            })
            .catch(error => showAxiosErrorToast('Failed to fetch media storage data', error))
            .finally(() => { this.loading = false; console.log(this.mediaRequests, this.photos) });
      }
    },

    filters: {
      toLocalDateTime: function(value) {
        if (value) {
          return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
        }
      },
    },
  }
</script>