<template>
  <b-container class="container mt-2">
    <h1>Meters report</h1>

    <div class="report-type-tabs">
      <b-card no-body>
        <b-tabs pills card>
          <b-tab title="Range" active>
            <RangeReportTab/>
          </b-tab>
          <b-tab title="Hourly">
            <HourlyReportTab />
          </b-tab>
          <b-tab title="Daily">
            <DailyReportTab />
          </b-tab>
          <b-tab title="Monthly">
            <MonthlyReportTab />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

  </b-container>
</template>


<script>
import RangeReportTab from "@/components/meters/RangeReportTab";
import HourlyReportTab from "@/components/meters/HourlyReportTab";
import DailyReportTab from "@/components/meters/DailyReportTab";
import MonthlyReportTab from "@/components/meters/MonthlyReportTab";

export default {
  name: 'MatersReportPage',
  components: {
    RangeReportTab,
    HourlyReportTab,
    DailyReportTab,
    MonthlyReportTab,
  },
}
</script>
