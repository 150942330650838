<template>
  <b-overlay :show="!metersData" rounded="sm" spinner-variant="primary" spinner-type="grow">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="mb-0">📈 Meters</h5>
          <b-badge v-if="!hasFreshData" pill variant="danger" v-b-tooltip.hover :title="lastUpdatedTooltip">No fresh data</b-badge>
        </div>
      </template>

      <div class="d-flex align-items-center justify-content-between">
        <span>Current: <b>{{currentLoad}}</b> KW</span>
        <span v-if="currentTariff">
          <BIconLightningFill :variant="currentTariff === 'High'? 'danger' : 'primary'"/>
          {{currentTariff}} tariff
        </span>
      </div>

      <hr>

      Consumption:
      <b-row class="mt-2">
        <b-col class="pr-2 pl-2"></b-col>
        <b-col class="pr-2 pl-1 text-center" v-b-tooltip.hover title="High tariff, kWh"><BIconLightningFill variant="danger"/></b-col>
        <b-col class="pr-2 pl-1 text-center" v-b-tooltip.hover title="Low tariff, kWh"><BIconLightningFill variant="primary"/></b-col>
        <b-col class="pr-2 pl-1 text-center" v-b-tooltip.hover title="Gas, m³"><BIconBrightnessAltHighFill variant="warning"/></b-col>
        <b-col class="pr-1 pl-1 text-center"><BIconCurrencyEuro/></b-col>
      </b-row>

      <b-row v-for="consumptionEntry in consumption" v-bind:key="consumptionEntry.period">
        <b-col class="pr-2 pl-2 text-center"><b>{{ consumptionEntry.period }}</b></b-col>
        <b-col class="pr-2 pl-1 text-center">{{ consumptionEntry.energyHigh.value.toFixed(2) }}</b-col>
        <b-col class="pr-2 pl-1 text-center">{{ consumptionEntry.energyLow.value.toFixed(2) }}</b-col>
        <b-col class="pr-2 pl-1 text-center">{{ consumptionEntry.gas.value.toFixed(2) }}</b-col>
        <b-col class="pr-1 pl-1 text-center"><b>{{ consumptionEntry.price.value.toFixed(2) }}</b></b-col>
      </b-row>

    </b-card>
  </b-overlay>
</template>


<script>
import {
  BIconBrightnessAltHighFill, BIconCurrencyEuro, BIconLightningFill} from "bootstrap-vue";
import {DateTime} from "luxon";

export default {
  name: 'MetersDashboardControl',
  props: {
    metersData: Object,
  },
  components: {
    BIconBrightnessAltHighFill,
    BIconLightningFill,
    BIconCurrencyEuro,
  },

  data: function() {
    return {
      currentLoad: "?",
      currentTariff: null,
      lastUpdatedTooltip: "",
      consumption: [],
      hasFreshData: false,
    }
  },

  watch: {
    metersData() {
      if (!this.metersData) {
        return;
      }

      this.currentLoad = this.metersData.currentLoad? this.metersData.currentLoad.value.toFixed(2) : "?";
      this.currentTariff = this.metersData.currentTariff;
      this.consumption = this.metersData.consumption;

      this.hasFreshData = this.metersData.hasFreshData;
      if (this.metersData.hasFreshData) {
        this.lastUpdatedTooltip = "";
      } else {
        let dateTimeStr = "?";
        if (this.metersData.lastReadingTimestamp) {
          let dateTime = DateTime.fromSeconds(this.metersData.lastReadingTimestamp);
          dateTimeStr = dateTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
        }
        this.lastUpdatedTooltip = "Last readings: " + dateTimeStr;
      }
    }
  },

}
</script>