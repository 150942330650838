<template>
  <b-container class="container mt-2">
    <h1>System metrics</h1>

    <b-form-row class="align-items-center">
      <b-col cols="auto">Filter</b-col>
      <b-col cols="auto">
        <b-form-select
            id="cbSelectFilter"
            v-model="selectedFilter"
            :options="filters"
            @change="reloadData"
            :disabled="loading"
        />
      </b-col>
      <b-col cols="auto">
        <RefreshButton :loading="loading" @click="reloadData" />
      </b-col>
    </b-form-row>

    <div class="mt-4" />

    <h4>Counters ({{Object.keys(counters).length}})</h4>
    <b-table-simple small hover striped responsive table-class="w-auto">
      <b-tbody>
        <b-tr v-for="(metricValue, metricName) in counters" v-bind:key="metricName">
          <b-td>{{metricName}}</b-td>
          <b-td>{{metricValue}}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h4>Gauges ({{Object.keys(gauges).length}})</h4>
    <b-table-simple small hover striped responsive table-class="w-auto">
      <b-tbody>
        <b-tr v-for="(metricValue, metricName) in gauges" v-bind:key="metricName">
          <b-td>{{metricName}}</b-td>
          <b-td>{{metricValue}}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h4>Meters ({{Object.keys(meters).length}})</h4>
    <b-table-simple small hover striped responsive table-class="w-auto">
      <b-thead>
        <b-tr>
          <b-th>Metric</b-th>
          <b-th>count</b-th>
          <b-th>1 min rate</b-th>
          <b-th>5 min rate</b-th>
          <b-th>15 min rate</b-th>
          <b-th>mean rate</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(meterValue, meterName) in meters" v-bind:key="meterName">
          <b-td>{{meterName}}</b-td>
          <b-td>{{meterValue.count}}</b-td>
          <b-td>{{meterValue.m1Rate.toFixed(3)}}</b-td>
          <b-td>{{meterValue.m5Rate.toFixed(3)}}</b-td>
          <b-td>{{meterValue.m15Rate.toFixed(3)}}</b-td>
          <b-td>{{meterValue.meanRate.toFixed(3)}}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h4>Timers ({{Object.keys(timers).length}})</h4>
    <b>Duration units: </b>{{durationUnit}}
    <b-table-simple small hover striped responsive table-class="w-auto">
      <b-thead>
        <b-tr>
          <b-th>Metric</b-th>
          <b-th>count</b-th>
          <b-th>min</b-th>
          <b-th>mean</b-th>
          <b-th>max</b-th>
          <b-th>p50</b-th>
          <b-th>p75</b-th>
          <b-th>p95</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(timerValue, timerName) in timers" v-bind:key="timerName">
          <b-td>{{timerName}}</b-td>
          <b-td>{{timerValue.count}}</b-td>
          <b-td>{{timerValue.min.toFixed(1)}}</b-td>
          <b-td>{{timerValue.mean.toFixed(1)}}</b-td>
          <b-td>{{timerValue.max.toFixed(1)}}</b-td>
          <b-td>{{timerValue.p50.toFixed(1)}}</b-td>
          <b-td>{{timerValue.p75.toFixed(1)}}</b-td>
          <b-td>{{timerValue.p95.toFixed(1)}}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h4>Histograms ({{Object.keys(histograms).length}})</h4>
    <b-table-simple small hover striped responsive table-class="w-auto">
      <b-thead>
        <b-tr>
          <b-th>Metric</b-th>
          <b-th>count</b-th>
          <b-th>min</b-th>
          <b-th>mean</b-th>
          <b-th>max</b-th>
          <b-th>p50</b-th>
          <b-th>p75</b-th>
          <b-th>p95</b-th>
          <b-th>p98</b-th>
          <b-th>p99</b-th>
          <b-th>p99.9</b-th>
          <b-th>std dev</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(histogramValue, histogramName) in histograms" v-bind:key="histogramName">
          <b-td>{{histogramName}}</b-td>
          <b-td>{{histogramValue.count}}</b-td>
          <b-td>{{histogramValue.min.toFixed(1)}}</b-td>
          <b-td>{{histogramValue.mean.toFixed(1)}}</b-td>
          <b-td>{{histogramValue.max.toFixed(1)}}</b-td>
          <b-td>{{histogramValue.p50.toFixed(1)}}</b-td>
          <b-td>{{histogramValue.p75.toFixed(1)}}</b-td>
          <b-td>{{histogramValue.p95.toFixed(1)}}</b-td>
          <b-td>{{histogramValue.p98.toFixed(1)}}</b-td>
          <b-td>{{histogramValue.p99.toFixed(1)}}</b-td>
          <b-td>{{histogramValue.p999.toFixed(1)}}</b-td>
          <b-td>{{histogramValue.stdDev.toFixed(1)}}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

  </b-container>
</template>


<script>
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";
import RefreshButton from "@/components/common/RefreshButton";

export default {
  name: 'SystemMetricsPage',
  components: {
    RefreshButton,
  },

  data: function() {
    return {
      loading: true,
      selectedFilter: null,
      filters: null,
      durationUnit: null,
      counters: {},
      gauges: {},
      meters: {},
      timers: {},
      histograms: {},
    }
  },

  created: async function() {
    this.loading = true;
    try {
      let metricsFiltersResponse = await axios.get("/api/metrics/metrics_filters");
      this.filters = metricsFiltersResponse.data.metricsFilters;
      this.selectedFilter = this.filters[0].value;
      await this.reloadData();
    } catch (error) {
      showAxiosErrorToast("Failed to fetch metrics filters", error);
    }
  },

  methods: {
    reloadData: async function() {
      this.loading = true;
      try {
        let params = { filter: this.selectedFilter }
        let metricsResponse = await axios.get("/api/metrics", { params: params });
        this.counters = metricsResponse.data.counters;
        this.gauges = metricsResponse.data.gauges;
        this.meters = metricsResponse.data.meters;
        this.timers = metricsResponse.data.timers;
        this.histograms = metricsResponse.data.histograms;
        this.durationUnit = metricsResponse.data.durationUnit;
      } catch (error) {
        showAxiosErrorToast("Failed to fetch metrics", error);
      } finally {
        this.loading = false;
      }
    }
  },
}
</script>