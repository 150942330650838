<template>
  <b-modal id="device-logs-modal" v-model="showModal" centered size="lg" title="Device logs">

    <AgentsSelect :agents-list="agentsList" v-model="selectedAgent" @change="onAgentSelected"/>

    <b-overlay :show="logsLoading" rounded="sm" spinner-variant="primary" spinner-type="grow">
      <div id="logsPanel" class="logs-panel mt-3">
        <div v-for="entry in deviceLogEntries" v-bind:key="entry.timestamp + entry.message">
          <b>[{{ entry.severity }}]</b> &nbsp;
          <b>{{ entry.timestamp | toLocalDateTime }}</b>
          <br>
          <span>{{ entry.message }}</span>
          <br><br>
        </div>
      </div>
    </b-overlay>

    <template #modal-footer>
      <div class="mr-auto">
        <RefreshButton default-label="Get device info" loading-label="Requesting" variant="info" no-default-icon class="mr-1"
                       :loading="deviceInfoRequesting" @click="requestDeviceInfo" />
        <RefreshButton default-label="Refresh" loading-label="Refreshing" variant="success" no-default-icon
                       :loading="logsLoading" @click="reloadLogs" />
      </div>
      <b-button variant="secondary" @click="showModal=false">Close</b-button>
    </template>

  </b-modal>
</template>


<script>
import AgentsSelect from "@/components/agents/AgentsSelect";
import RefreshButton from "@/components/common/RefreshButton";
import axios from "axios";
import {showAxiosErrorToast, showInfoToast} from "@/util/toasts";
import {DateTime} from "luxon";

export default {
  name: 'DeviceLogsModal',

  components: {
    AgentsSelect,
    RefreshButton,
  },

  props: {
    agentsList: Array,
    clickedAgent: Object,
  },

  data: function() {
    return {
      showModal: false,
      selectedAgent: null,
      deviceLogEntries: [],

      deviceInfoRequesting: false,
      logsLoading: false,
    }
  },

  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'device-logs-modal') {
        this.reloadLogs();
      }
    })
  },

  methods: {
    reloadLogs: function() {
      this.logsLoading = true;
      let queryParams = { deviceId: this.selectedAgent };
      axios.get('/api/agent/logs', { params: queryParams })
          .then( response => this.deviceLogEntries = response.data.entries )
          .catch( error => showAxiosErrorToast("Failed to fetch device logs", error) )
          .finally( () => this.logsLoading = false )
    },

    onAgentSelected: function() {
      if (this.showModal) {
        this.reloadLogs();
      }
    },

    requestDeviceInfo: function() {
      this.deviceInfoRequesting = true;
      let postBody = {
        targetDevice: this.selectedAgent,
      };
      axios.post('/api/device_cmd/get_device_info', postBody)
          .then( response => showInfoToast("Device info requested. Command id: " + response.data.commandId) )
          .catch( error => showAxiosErrorToast("Failed to request device info", error) )
          .finally( () => this.deviceInfoRequesting = false )
    },
  },

  watch: {
    clickedAgent: function() {
      this.selectedAgent = this.clickedAgent.id;
    },
  },

  filters: {
    toLocalDateTime: function(value) {
      return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    },
  },
}
</script>


<style scoped>
  .logs-panel {
    height: 300px;
    overflow-y: scroll;
    font-family: monospace;
    border: solid black 1px;
    border-radius: 4px;
    padding: 3px;
    background-color: lightgray;
    white-space: pre-line;
  }
</style>