<template>
  <b-modal id="device-metrics-modal" v-model="showModal" centered size="lg" title="Device metrics">

    <AgentsSelect :agents-list="agentsList" v-model="selectedAgent" @change="onAgentSelected"/>

    <b-overlay :show="metricsLoading" rounded="sm" spinner-variant="primary" spinner-type="grow">
      <b-table-lite striped hover small responsive
                    :items="metrics"
                    :fields="metricsFields"
                    class="mt-4 metrics-table"
      >
        <template #cell(updatedAtTs)="data">
          <div v-b-tooltip.hover :title="data.value | toLocalDateTime" >
            {{ data.value | toTimeIntervalPretty }}
          </div>
        </template>
      </b-table-lite>
    </b-overlay>

    <template #modal-footer>
      <div class="mr-auto">
        <RefreshButton default-label="Request metrics" loading-label="Requesting" variant="info" no-default-icon class="mr-1"
                       :loading="metricsRequesting" @click="requestMetricsFromDevice" />
        <RefreshButton default-label="Refresh" loading-label="Refreshing" variant="success" no-default-icon
                       :loading="metricsLoading" @click="reloadMetricsFromServer" />
      </div>
      <b-button variant="secondary" @click="showModal=false">Close</b-button>
    </template>
  </b-modal>
</template>


<script>
import AgentsSelect from "@/components/agents/AgentsSelect";
import RefreshButton from "@/components/common/RefreshButton";
import axios from "axios";
import {showAxiosErrorToast, showInfoToast} from "@/util/toasts";
import {DateTime} from "luxon";
import {timeIntervalFromPretty} from "@/util/datetime";

export default {
  name: 'DeviceMetricsModal',

  components: {
    AgentsSelect,
    RefreshButton,
  },

  props: {
    agentsList: Array,
    clickedAgent: Object,
  },

  data: function() {
    return {
      showModal: false,
      selectedAgent: null,
      metricsRequesting: false,
      metricsLoading: false,
      metrics: [],
      metricsFields: [
        { key: 'key', label: 'Key' },
        { key: 'value', label: 'Value' },
        { key: 'updatedAtTs', label: 'Updated at' },
      ],
    }
  },

  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'device-metrics-modal') {
        this.reloadMetricsFromServer();
      }
    })
  },

  methods: {
    reloadMetricsFromServer: function() {
      this.metricsLoading = true;
      let queryParams = { deviceId: this.selectedAgent };
      axios.get('/api/agent/metrics', { params: queryParams })
          .then( response => this.metrics = response.data.metrics )
          .catch( error => showAxiosErrorToast("Failed to load device metrics from server", error) )
          .finally( () => this.metricsLoading = false )
    },

    onAgentSelected: function() {
      if (this.showModal) {
        this.reloadMetricsFromServer();
      }
    },

    requestMetricsFromDevice: function() {
      this.metricsRequesting = true;
      let postBody = {
        targetDevice: this.selectedAgent,
      };
      axios.post('/api/device_cmd/request_metrics', postBody)
          .then( response => showInfoToast("Metrics requested from device. Command id: " + response.data.commandId) )
          .catch( error => showAxiosErrorToast("Failed to request metrics from device", error) )
          .finally( () => this.metricsRequesting = false )
    },
  },

  watch: {
    clickedAgent: function() {
      this.selectedAgent = this.clickedAgent.id;
    },
  },

  filters: {
    toLocalDateTime: function(value) {
      return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },

    toTimeIntervalPretty: function(value) {
      return timeIntervalFromPretty(DateTime.fromSeconds(value));
    }
  },
}
</script>


<style scoped>
  .metrics-table {
    height: 300px;
    overflow: scroll
  }
</style>