<template>
  <b-list-group-item class="pt-2 pb-2 pl-0 pr-0 d-flex justify-content-between align-items-start">
    <div>
      <h5 class="mb-0">
        {{ runLogEntry.startedAt | toDateTimeShort }}
        <template v-if="runLogEntry.stoppedAt">
          - {{ runLogEntry.stoppedAt | toTimeShort }}
          ({{ heaterRunDuration }})
        </template>
        <template v-else>
          (still running)
        </template>
      </h5>
      <small>
        started by: <b>{{ runLogEntry.startedBy }}</b>
        <template v-if="runLogEntry.stoppedBy">
          , stopped by: <b>{{ runLogEntry.stoppedBy }}</b>
        </template>
      </small>
      <br>

      <div v-for="tempChange in runLogEntry.temperatureChanges" v-bind:key="tempChange.locationDisplayName">
        <small>
          {{ tempChange.locationDisplayName }}: {{ tempChange.startValue.toFixed(1) }} ℃ →
          <template v-if="tempChange.endValue">
            {{ tempChange.endValue.toFixed(1) }}
            ({{ tempChange.endValueTimestamp | toTimeShort }})
          </template>
          <template v-else>
            ?
          </template>
        </small><br>
      </div>
    </div>

    <h4 v-if="runLogEntry.costEur">€&nbsp;{{ runLogEntry.costEur.toFixed(2) }}</h4>

  </b-list-group-item>
</template>


<script>
import {DateTime} from "luxon";

export default {
  name: 'HeaterRunLogEntryControl',

  props: {
    runLogEntry: null,
  },

  computed: {
    heaterRunDuration: function() {
      const startedAt = DateTime.fromSeconds(this.runLogEntry.startedAt);
      const stoppedAt = DateTime.fromSeconds(this.runLogEntry.stoppedAt);
      const timeDiff = stoppedAt.diff(startedAt);
      const diffMinutes = timeDiff.as('minutes') | 0;
      if (diffMinutes < 120)
        return diffMinutes + " minutes";
      else {
        const diffHours = timeDiff.as('hours');
        return diffHours.toFixed(1) + " hours"
      }
    }
  },

  filters: {
    toDateTimeShort(value) {
      return DateTime.fromSeconds(value).toFormat('d MMM (EEE) HH:mm');
    },

    toTimeShort(value) {
      return DateTime.fromSeconds(value).toLocaleString(DateTime.TIME_SIMPLE);
    },
  }
}
</script>