<template>
  <div>
    <b-button variant="outline-primary" @click="showModal=true">ZCL DataRequest</b-button>

    <b-modal id="zcl-data-request-modal" v-model="showModal" centered size="lg" title="Send ZCL DataRequest">

      <b-form-row class="align-items-center">
        <b-col cols="2"><label class="col-form-label">Target</label></b-col>
        <b-col cols="10">
          <b-form-select :options="targetDevices" v-model="selectedDevice"></b-form-select>
        </b-col>
      </b-form-row>

      <b-form-row class="align-items-center mt-3">
        <b-col cols="2" md="2"><label class="col-form-label">Cluster</label></b-col>
        <b-col cols="5" md="7">
          <b-form-select :options="clusters" v-model="selectedCluster"></b-form-select>
        </b-col>
        <b-col cols="5" md="3">
          <b-form-checkbox v-model="isGenericCommand">General command</b-form-checkbox>
        </b-col>
      </b-form-row>

      <b-form-row class="align-items-center mt-3">
        <b-col cols="2" md="2"><label class="col-form-label">Command</label></b-col>
        <b-col cols="5" md="7">
          <b-form-select :options="commands" v-model.number="selectedCommand" />
        </b-col>
        <b-col cols="5" md="3">
          <b-form-input type="number" placeholder="Cmd num" v-model="selectedCommand"/>
        </b-col>
      </b-form-row>


      <b-form-row class="align-items-center mt-3">
        <b-col cols="2"><label class="col-form-label">Payload</label></b-col>
        <b-col cols="10">
          <b-form-input placeholder="Space-separated decimal values" v-model="payload"/>
        </b-col>
      </b-form-row>

      <template #modal-footer>
        <b-button variant="secondary" @click="showModal=false">Close</b-button>
        <b-button variant="success" @click="sendCommand" :disabled="sending">
          <section v-if="sending">
            <b-spinner small></b-spinner>
            Sending...
          </section>
          <section v-else>
            Send
          </section>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>


<script>
import {ZigbeeClusters, ZigbeeCommands} from "@/util/zigbee_constants";
import axios from "axios";
import {showAxiosErrorToast, showInfoToast, showWarningToast} from "@/util/toasts";

export default {
  name: 'ZclDataRequestModal',
  props: {
    networkNodes: Array,
  },

  data: function() {
    return {
      showModal: false,
      sending: false,

      clusters: ZigbeeClusters,

      selectedDevice: null,
      selectedCluster: ZigbeeClusters[0].value,
      selectedCommand: null,
      isGenericCommand: false,
      payload: '',
    }
  },

  computed: {
    commands: function() {
      if (this.isGenericCommand) {
        return ZigbeeCommands['General'];
      } else {
        return ZigbeeCommands[this.selectedCluster.key];
      }
    },

    targetDevices: function() {
      if (!this.networkNodes) {
        return []
      }
      return this.networkNodes.map(node => (
          {value: node.ieeeAddress, text: node.deviceType + " - " + node.description + " - " + node.location}
      ));
    }
  },

  watch: {
    targetDevices: function() {
      if (this.targetDevices && this.targetDevices.length > 0) {
        this.selectedDevice = this.targetDevices[0].value;
      }
    },

    commands: function() {
      if (this.commands && this.commands.length > 0) {
        this.selectedCommand = this.commands[0].value;
      }
    },
  },

  methods: {
    sendCommand: function() {
      if (this.selectedDevice === null || this.selectedCluster === null || this.selectedCommand === null) {
        showWarningToast("Please fill in all fields");
        return;
      }
      let requestBody = {
        deviceIeeeAddress: this.selectedDevice,
        clusterId: this.selectedCluster.id,
        commandId: this.selectedCommand,
        payload: this.payload,
        isGeneral: this.isGenericCommand
      };
      this.sending = true;
      axios.post("/api/zigbee/zcl_data_request", requestBody)
        .then(response => {
          showInfoToast("ZCL DataRequest sent! Command id: " + response.data.commandId);
        })
        .catch(error => {
          showAxiosErrorToast("Failed to send ZCL DataRequest", error)
        })
        .finally(() => {
          this.sending = false;
        })
    },
  },
}
</script>