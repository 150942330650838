import { app } from '@/main'

export function showErrorToast(body) {
  app.$bvToast.toast(body, {
    title: `Error`,
    variant: 'danger',
    solid: true,
    autoHideDelay: 10000,
  });
}

export function showAxiosErrorToast(message, axiosErr) {
  message += ". ";
  if (axiosErr.response) {
    if (axiosErr.response.data && axiosErr.response.data.message) {
      message += axiosErr.response.data.message + ". ";
    } else {
      message += axiosErr.message + ". "
    }
  } else if (axiosErr.request) {
    message += "Request failed."
  } else {
    message += "Generic error. " + axiosErr.message;
  }
  return showErrorToast(message);
}

export function showWarningToast(body) {
  app.$bvToast.toast(body, {
    title: `Warning`,
    variant: 'warning',
    solid: true,
    autoHideDelay: 10000,
  });
}

export function showInfoToast(body) {
  app.$bvToast.toast(body, {
    title: `Info`,
    variant: 'success',
    solid: true,
    autoHideDelay: 10000,
  });
}