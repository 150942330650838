<template>
  <b-container class="container mt-2">
    <h1>System info</h1>

    <table class="table table-sm table-responsive">
      <tr>
        <td><b>Server time</b></td>
        <td>{{serverTime}}</td>
      </tr>
      <tr>
        <td><b>Database size</b></td>
        <td>{{databaseSize}}</td>
      </tr>
    </table>

    <h3>Root partition</h3>
    <pre>
      <p class="text-monospace">{{rootPartition}}</p>
    </pre>
  </b-container>
</template>


<script>
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";

export default {
  name: 'SystemInfoPage',
  components: {},
  props: {},

  data: function() {
    return {
      serverTime: null,
      databaseSize: null,
      rootPartition: null,
    }
  },

  created: async function() {
    try {
      const systemInfoResponse = await axios.get('/api/system/info');
      this.serverTime = systemInfoResponse.data.serverTime;
      this.databaseSize = systemInfoResponse.data.databaseSize;
      this.rootPartition = systemInfoResponse.data.rootPartition;
    } catch (error) {
      showAxiosErrorToast("Failed to fetch system info", error);
    }
  },
}
</script>