<template>
  <div>
    <DateTimeControl label="From:" :date-time.sync='startDateTime' default-time="00:00" :max-date="maxDate" class="mb-2" />
    <DateTimeControl label="To:" :date-time.sync='endDateTime' default-time="23:59" :max-date="maxDate" class="mb-2" />
    <RefreshButton variant="outline-success" default-label="Generate report" loading-label="Generating" no-default-icon
                   :loading="reportGenerating" @click="generateRangeReport"/>

    <b-overlay :show="reportGenerating" rounded="sm" spinner-variant="primary" spinner-type="grow">
      <b-table-simple v-if="report" small responsive table-class="w-auto" class="mt-3">
        <b-tbody>
          <b-tr>
            <b-td><b>Energy (high)</b></b-td>
            <b-td>{{report.consumption.energyHighTariff.value.toFixed(3)}} <small>kWh</small></b-td>
            <b-td>€ {{report.consumption.energyHighTariff.priceEur.toFixed(2)}}</b-td>
          </b-tr>
        </b-tbody>
        <b-tr>
          <b-td><b>Energy (low)</b></b-td>
          <b-td>{{report.consumption.energyLowTariff.value.toFixed(3)}} <small>kWh</small></b-td>
          <b-td>€ {{report.consumption.energyLowTariff.priceEur.toFixed(2)}}</b-td>
        </b-tr>
        <b-tr>
          <b-td><b>Gas</b></b-td>
          <b-td>{{report.consumption.gas.value.toFixed(3)}} <small>m³</small></b-td>
          <b-td>€ {{report.consumption.gas.priceEur.toFixed(2)}}</b-td>
        </b-tr>
        <b-tr variant="secondary">
          <b-td><b>Total:</b></b-td>
          <b-td></b-td>
          <b-td><b>€ {{(report.consumption.energyHighTariff.priceEur + report.consumption.energyLowTariff.priceEur + report.consumption.gas.priceEur).toFixed(2)}}</b></b-td>
        </b-tr>
      </b-table-simple>

      <b-table-lite v-if="report"
                    small responsive hover
                    head-variant="dark"
                    :items="tableEntries"
                    :fields="tableFields"
                    class="mt-4" />

    </b-overlay>
  </div>
</template>


<script>
import {showAxiosErrorToast, showWarningToast} from "@/util/toasts";
import RefreshButton from "@/components/common/RefreshButton";
import DateTimeControl from "@/components/meters/DateTimeControl";
import axios from "axios";
import {DateTime} from "luxon";

export default {
  name: 'RangeReportTab',
  props: {
  },

  components: {
    RefreshButton,
    DateTimeControl,
  },

  data: function() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return {
      maxDate: today,
      reportGenerating: false,
      startDateTime: null,
      endDateTime: null,

      report: null,
    }
  },

  created: function() {
    this.tableFields = [
      { key: 'param', label: 'Parameter' },
      { key: 'from', label: 'From' },
      { key: 'to', label: 'To' },
    ]
  },

  methods: {
    generateRangeReport: function() {
      if (!this.startDateTime || !this.endDateTime) {
        showWarningToast("Please specify 'from' and 'to' dates");
        return;
      }
      let fromEpoch = this.startDateTime.toSeconds();
      let toEpoch = this.endDateTime.toSeconds();
      if (fromEpoch >= toEpoch) {
        showWarningToast("Start date should be before end date");
        return;
      }

      this.reportGenerating = true;
      let queryParams = {
        from: fromEpoch,
        to: toEpoch,
      }
      axios.get('/api/meters/reports/range', { params: queryParams })
        .then( response => this.report = response.data)
        .catch( error => showAxiosErrorToast("Failed to generate range report", error) )
        .finally( () => this.reportGenerating = false )
    },

    toLocalDateTime: function(value) {
      return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
  },

  computed: {
    tableEntries: function() {
      return [
        { param: 'Entry timestamp', from: this.toLocalDateTime(this.report.startReading.timestamp), to: this.toLocalDateTime(this.report.endReading.timestamp) },
        { param: 'Energy reading timestamp', from: this.toLocalDateTime(this.report.startReading.deviceTimestamp), to: this.toLocalDateTime(this.report.endReading.deviceTimestamp) },
        { param: 'Gas reading timestamp', from: this.toLocalDateTime(this.report.startReading.gasReadingTimestamp), to: this.toLocalDateTime(this.report.endReading.gasReadingTimestamp) },
        { param: 'Energy - high tariff value', from: this.report.startReading.highTariffReading.toFixed(3), to: this.report.endReading.highTariffReading.toFixed(3) },
        { param: 'Energy - low tariff value', from: this.report.startReading.lowTariffReading.toFixed(3), to: this.report.endReading.lowTariffReading.toFixed(3) },
        { param: 'Gas value', from: this.report.startReading.gasReadingValue.toFixed(3), to: this.report.endReading.gasReadingValue.toFixed(3) },
      ]
    }
  },
}
</script>