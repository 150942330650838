import { render, staticRenderFns } from "./BoilerParamsControl.vue?vue&type=template&id=04164573&scoped=true&"
import script from "./BoilerParamsControl.vue?vue&type=script&lang=js&"
export * from "./BoilerParamsControl.vue?vue&type=script&lang=js&"
import style0 from "./BoilerParamsControl.vue?vue&type=style&index=0&id=04164573&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04164573",
  null
  
)

export default component.exports