<template>
  <div>
    <div class="mt-3">
      <label for="range-boiler-temperature" class="mb-0">Target boiler temperature</label>
      <b-row class="">
        <b-col cols="9">
          <b-form-input id="range-boiler-temperature"
                        v-model.number="temperatureLocal" @change="onTemperatureUpdated()"
                        type="range" min="30" max="80" step="1"></b-form-input>
        </b-col>
        <b-col cols="3" class="pl-0 pr-0">
          {{ temperatureLocal }} ℃
        </b-col>
      </b-row>
    </div>

    <div class="mt-2">
      <label for="range-timeout" class="mb-0">Timeout</label>
      <b-row class="">
        <b-col cols="9">
          <b-form-input id="range-timeout"
                        v-model.number="timeoutMinutesLocal" @change="onTimeoutUpdated()"
                        type="range" min="10" max="60" step="1"></b-form-input>
        </b-col>
        <b-col cols="3" class="pl-0 pr-0">
          {{ timeoutMinutesLocal }} min
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManualHeatingParamsControl',
  props: {
    targetBoilerTemperature: Number,
    heatingTimeoutMinutes: Number,
  },

  data: function() {
    return {
      // we store them separately from props because we want to update (and display to user) values in realtime,
      // while they move the slider, but actual update to props should happen once moving is done
      temperatureLocal: 0,
      timeoutMinutesLocal: 0,
    }
  },

  created() {
    this.temperatureLocal = this.targetBoilerTemperature;
    this.timeoutMinutesLocal = this.heatingTimeoutMinutes;
  },

  watch: {
    targetBoilerTemperature() {
      this.temperatureLocal = this.targetBoilerTemperature;
    },
    heatingTimeoutMinutes() {
      this.timeoutMinutesLocal = this.heatingTimeoutMinutes;
    },
  },

  methods: {
    onTemperatureUpdated() {
      this.$emit('update:targetBoilerTemperature', this.temperatureLocal);
      this.$emit('change');
    },
    onTimeoutUpdated() {
      this.$emit('update:heatingTimeoutMinutes', this.timeoutMinutesLocal);
      this.$emit('change');
    },
  },
}
</script>