<template>
  <b-overlay :show="!homeActivityData" rounded="sm" spinner-variant="primary" spinner-type="grow">
    <b-card>
      <template #header>
        <h5 class="mb-0">🏃 Home activity</h5>
      </template>

      <b-list-group flush>
        <b-list-group-item class="pt-1 pb-1 pl-0 pr-0 d-flex justify-content-between align-items-center">
          <b>Door:</b>
          <span v-if="homeActivityData" v-b-tooltip.hover :title="homeActivityData.lastDoorActivityTimestamp | formatTimestamp">
            {{ homeActivityData.lastDoorActivityTimestamp | formatMoment }}
          </span>
        </b-list-group-item>
        <b-list-group-item class="pt-1 pb-1 pl-0 pr-0 d-flex justify-content-between align-items-center">
          <b>Motion:</b>
          <span v-if="homeActivityData" v-b-tooltip.hover :title="homeActivityData.lastMotionTimestamp | formatTimestamp">
            {{ homeActivityData.lastMotionTimestamp | formatMoment }}
          </span>
        </b-list-group-item>
      </b-list-group>

    </b-card>
  </b-overlay>
</template>


<script>
import {timeIntervalFromPretty} from "@/util/datetime";
import {DateTime} from "luxon";

export default {
  name: 'HomeActivityControl',
  components: {
  },
  props: {
    homeActivityData: Object,
  },

  filters: {
    formatMoment(value) {
      if (!value) {
        return "no data";
      }
      return timeIntervalFromPretty(DateTime.fromSeconds(value));
    },

    formatTimestamp(value) {
      if (!value) {
        return "";
      }
      return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    }
  }
}
</script>