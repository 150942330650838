<template>
  <b-container class="container mt-2">
    <div class="d-flex align-items-center">
      <h1>Climate</h1>
      <RefreshButton class="ml-3" variant="outline-success" :loading="loading" @click="refreshData" />
    </div>


    <b-table-simple small responsive striped table-class="w-auto" class="mt-3">
      <b-overlay :show="loading" rounded="sm" spinner-variant="primary" spinner-type="grow">
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>Location</b-th>
          <b-th>Temperature</b-th>
          <b-th>Humidity</b-th>
          <b-th>Pressure</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="latestReadings">
        <b-tr v-for="dataInLocation in latestReadings" v-bind:key="dataInLocation.locationDisplayName">
          <b-td><b>{{ dataInLocation.locationDisplayName }}</b></b-td>
          <b-td>{{ dataInLocation.temperature | prettyDouble }} ℃</b-td>
          <b-td>{{ dataInLocation.humidity | prettyDouble }}%</b-td>
          <b-td>{{ dataInLocation.pressure | prettyDouble }} mmHg</b-td>
          <b-td>
            <b-badge v-b-tooltip.hover :title="dataInLocation.timestamp | recencyTooltip" :variant="dataInLocation.timestampRecencyIndicator | recencyBadgeVariant">
              {{ dataInLocation.timestamp | recencyText }}
            </b-badge>
          </b-td>
        </b-tr>
      </b-tbody>
      </b-overlay>
    </b-table-simple>


    <h2>Charts</h2>
    <b-form-group>
      <b-form-radio-group
          v-model="selectedInterval"
          :options="reportIntervalOptions"
          button-variant="outline-primary"
          @change="onReportIntervalChange"
          size="sm"
          buttons
      ></b-form-radio-group>
    </b-form-group>

    <ClimateChartControl :loading="loading" title="Temperature" units=" ℃" climate-component="t" :chart-points="chartsData" />
    <ClimateChartControl :loading="loading" title="Humidity" units="%" climate-component="h" :chart-points="chartsData" />
    <ClimateChartControl :loading="loading" title="Pressure" units=" mmHg" climate-component="p" :chart-points="chartsData" />

  </b-container>
</template>


<script>
import RefreshButton from "@/components/common/RefreshButton";
import ClimateChartControl from "@/components/climate/ClimateChartControl"
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";
import {DateTime} from "luxon";

export default {
  name: 'ClimatePage',
  components: {
    RefreshButton,
    ClimateChartControl,
  },

  data: function() {
    return {
      loading: true,
      latestReadings: null,
      selectedInterval: 24,
      chartsData: null,
    }
  },

  created() {
    this.reportIntervalOptions = [
      { text: '3 hours', value: 3 },
      { text: '6 hours', value: 6 },
      { text: '12 hours', value: 12 },
      { text: '24 hours', value: 24 },
      { text: '3 days', value: 3 * 24 },
      { text: '7 days', value: 7 * 24 },
    ];

    this.refreshData();
  },

  methods: {
    refreshData: function() {
      this.loading = true;
      let queryParams = { hoursBack: this.selectedInterval }
      axios.get('/api/climate/dashboard', { params: queryParams })
      .then( response => this.applyFreshData(response.data) )
      .catch( error => showAxiosErrorToast("Failed to fetch climate data", error) )
      .finally( () => this.loading = false )
    },

    applyFreshData: function(data) {
      this.latestReadings = data.latestReadings;
      this.chartsData = data.chartsData;
    },

    onReportIntervalChange: function() {
      this.refreshData();
    }
  },

  filters: {
    prettyDouble(value) {
      if (value) {
        return value.toFixed(1);
      }
    },

    recencyTooltip(value) {
      if (value) {
        return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      }
    },

    recencyText(value) {
      const updatedAtDateTime = DateTime.fromSeconds(value);
      const timeDiff = DateTime.now().diff(updatedAtDateTime);
      const minutesAgo = timeDiff.as('minutes') | 0;
      if (minutesAgo <= 1) {
        return "Just now";
      } else if (minutesAgo < 120)
        return minutesAgo + " minutes ago";
      else {
        const hoursAgo = timeDiff.as('hours') | 0;
        if (hoursAgo <= 48) {
          return hoursAgo + " hours ago";
        } else {
          const daysAgo = timeDiff.as('days') | 0;
          return daysAgo + " days ago";
        }
      }
    },

    recencyBadgeVariant(value) {
      if (value === 'NoData') return 'secondary';
      else if (value === 'JustNow') return 'success';
      else if (value === 'Recent') return 'info';
      else if (value === 'Old') return 'warning';
      else if (value === 'VeryOld') return 'danger';
      else return 'dark';
    },
  }
}
</script>
