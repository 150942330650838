<template>
  <b-card no-body
      :border-variant="upToDate? 'success' : 'danger'"
      :header-border-variant="upToDate? 'success' : 'danger'"
      class="boiler-status-card">
    <template #header>
      <h5 class="mb-0">{{title}}</h5>
      <small>{{lastUpdate}}</small>
    </template>

    <b-list-group flush>
      <b-list-group-item v-for="(item, key) in items" v-bind:key="key" class="pt-1 pb-1 d-flex justify-content-between align-items-center">
        {{item.name}} <span><b>{{item.value}}</b> {{item.unit}}</span>
      </b-list-group-item>
    </b-list-group>

  </b-card>
</template>


<script>
export default {
  name: 'BoilerParamsControl',

  props: {
    title: String,
    items: Object,
    upToDate: Boolean,
    lastUpdate: String
  },
}
</script>


<style scoped>
  .boiler-status-card {
    width: 17rem;
  }

  small {
    font-size: 0.9em;
  }
</style>