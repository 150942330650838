<template>
  <b-overlay :show="busy" rounded="sm" spinner-variant="primary" spinner-type="grow">
    <b-table-simple v-if="report" small hover responsive bordered>
      <b-thead>
        <b-tr variant="secondary">
          <b-th>{{ unit }}</b-th>
          <b-th>Energy (high)</b-th>
          <b-th>Energy (low)</b-th>
          <b-th>Gas</b-th>
          <b-th>Total</b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr v-for="entry in report.entries" v-bind:key="entry.periondName">
          <b-td variant="primary"><b>{{ entry.periodName }}</b></b-td>

          <!-- this conditional rendering is the reason why we can not use b-table or b-table-light -->
          <template v-if="entry.consumption">
            <b-td>
              {{ entry.consumption.energyHighTariff.value.toFixed(2) }} <small>kWh</small>
              (€ {{ entry.consumption.energyHighTariff.priceEur.toFixed(2) }})
            </b-td>
            <b-td>
              {{ entry.consumption.energyLowTariff.value.toFixed(2) }} <small>kWh</small>
              (€ {{ entry.consumption.energyLowTariff.priceEur.toFixed(2) }})
            </b-td>
            <b-td>
              {{ entry.consumption.gas.value.toFixed(2) }} <small>m³</small>
              (€ {{ entry.consumption.gas.priceEur.toFixed(2) }})
            </b-td>
            <b-td variant="info">
              <b>€ {{ (entry.consumption.energyHighTariff.priceEur + entry.consumption.energyLowTariff.priceEur + entry.consumption.gas.priceEur).toFixed(2) }}</b>
            </b-td>
          </template>
          <template v-else>
            <b-td colspan="4" variant="secondary"><i>No data</i></b-td>
          </template>
        </b-tr>
      </b-tbody>
    </b-table-simple>

  </b-overlay>
</template>


<script>
export default {
  name: 'PeriodReportTableControl',
  props: {
    report: Object,
    unit: String,
    busy: Boolean,
  },

}
</script>