<template>
  <div id="app">
    <PageHeader :environment="environment" />
    <router-view></router-view>
  </div>
</template>

<script>
import PageHeader from './components/common/PageHeader.vue'

export default {
  name: 'App',

  components: {
    PageHeader,
  },

  computed: {
    environment: function() {
      return process.env.NODE_ENV;
    }
  }
}
</script>

<style>
#app {

}
</style>
