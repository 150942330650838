<template>
  <b-overlay :show="showSpinner" rounded="sm" spinner-variant="primary" spinner-type="grow">
    <b-card>
      <template #header>
        <h5 class="mb-0 mr-auto">🔌 Power outlets</h5>
      </template>

      <b-form-checkbox
          v-for="outlet in powerOutletsLocal"
          :key="outlet.id"
          v-model="outlet.turnedOn"
          @change="processStateChange(outlet)"
          switch
      >
        {{ outlet.displayName }}
      </b-form-checkbox>

    </b-card>
  </b-overlay>
</template>


<script>
import {showAxiosErrorToast} from "@/util/toasts";
import axios from "axios";

export default {
  name: 'PowerOutletsControl',
  props: {
    powerOutletsStatus: Array,
  },

  data: function() {
      return {
        powerOutletsLocal: this.powerOutletsStatus? [...this.powerOutletsStatus] : [],
        postingUpdate: false,
      }
  },

  watch: {
    powerOutletsStatus() {
      this.updateState(this.powerOutletsStatus);
    },
  },

  computed: {
    showSpinner() {
      return this.postingUpdate || !this.powerOutletsStatus;
    }
  },

  methods: {
    updateState: async function(newPowerOutletsState) {
      if (newPowerOutletsState && !this.postingUpdate) {
        this.powerOutletsLocal = [...newPowerOutletsState];
      }
    },

    processStateChange: async function(outlet) {
      this.postingUpdate = true;
      try {
        const cmd = outlet.turnedOn? "On" : "Off";
        const url = "/api/power_outlets/on_off/by_device_id/" + outlet.id + "/" + cmd;
        await axios.post(url);
      } catch (error) {
        showAxiosErrorToast("Failed to change power outlet state", error);
        this.$emit("force-update");
      } finally {
        this.postingUpdate = false;
      }
    },
  },
}
</script>


<style scoped>
</style>