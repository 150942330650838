<template>
  <b-container class="container mt-2">
    <h1>Events</h1>

    <b-form-row class="align-items-center">
      <b-col cols="auto">Filter</b-col>
      <b-col cols="auto" sm="auto">
        <b-form-select
            id="cbSelectFilter"
            v-model="selectedEventFilter"
            :options="eventFilters"
            @change="reloadData"
            :disabled="loading"
        />
      </b-col>
      <b-col cols="auto" sm="auto">
        <RefreshButton :loading="loading" @click="reloadData"/>
      </b-col>
    </b-form-row>

    <b-table striped hover small outlined responsive
             :items="eventsList"
             :fields="eventsTableFields"
             :busy="loading"
             class="mt-4"
             >
      <template #cell(id)="data">
        {{data.item.id}}
        <section v-if="data.item.originalId">
          ({{data.item.originalId}})
        </section>
      </template>

      <template #cell(severity)="data">
        <div class="align-items-center">
          <section v-if="data.item.severity === 'Debug'"><BIconArrowRightSquare variant="success" font-scale="1.3"/></section>
          <section v-else-if="data.item.severity === 'Info'"><BIconInfoCircle variant="primary" font-scale="1.3"/></section>
          <section v-else-if="data.item.severity === 'Warning'"><BIconExclamationTriangleFill variant="warning" font-scale="1.3"/></section>
          <section v-else-if="data.item.severity === 'Alert'"><BIconExclamationCircleFill variant="danger" font-scale="1.3"/></section>
        </div>
      </template>

      <template #cell(timestamp)="data">
        {{data.item.timestamp | formatDateTime}}
      </template>

      <template #cell(name)="data">
        <b>{{data.item.name}}</b>
      </template>

      <template #cell(args)="data">
        <VueJsonPretty :data="data.item.args | toJsonObject" />
      </template>
    </b-table>

  </b-container>
</template>


<script>
import {showAxiosErrorToast} from "@/util/toasts";
import axios from "axios";
import {DateTime} from "luxon";
import {
  BIconArrowRightSquare,
  BIconExclamationCircleFill,
  BIconExclamationTriangleFill,
  BIconInfoCircle
} from "bootstrap-vue";
import RefreshButton from "@/components/common/RefreshButton";
import VueJsonPretty from "vue-json-pretty";


export default {
  name: 'EventsPage',
  components: {
    BIconExclamationCircleFill,
    BIconExclamationTriangleFill,
    BIconInfoCircle,
    BIconArrowRightSquare,
    RefreshButton,
    VueJsonPretty,
  },
  props: {},

  data: function() {
    return {
      loading: true,
      eventFilters: null,
      selectedEventFilter: null,
      eventsList: null,

      eventsTableFields: [
        { key: "severity", label: "", tdClass: 'align-middle' },
        { key: "id", label: "ID" },
        { key: "timestamp", label: "Timestamp" },
        { key: "name", label: "Name" },
        { key: "origin", label: "Origin" },
        { key: "emittedBy", label: "Emitted by" },
        { key: "source", label: "Source" },
        { key: "args", label: "Json" },
      ]
    }
  },

  created: async function() {
    this.loading = true;
    try {
      let eventFiltersResponse = await axios.get("/api/events/event_filters");
      this.eventFilters = eventFiltersResponse.data.eventFilters;
      this.selectedEventFilter = this.eventFilters[0].value;
      await this.reloadData();
    } catch (error) {
      showAxiosErrorToast("Failed to fetch events filters", error);
    }
  },

  methods: {
    reloadData: async function() {
      try {
        this.loading = true;
        let params = { filter: this.selectedEventFilter }
        let eventsListResponse = await axios.get("/api/events/events", { params: params });
        this.eventsList = eventsListResponse.data.eventsList;
      } catch (error) {
        showAxiosErrorToast("Failed to fetch events list", error);
      } finally {
        this.loading = false;
      }
    }
  },

  filters: {
    formatDateTime: function(value) {
      return DateTime.fromSeconds(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },

    toJsonObject: function(value) {
      if (value) {
        try {
          return JSON.parse(value);
        }
        catch {
          // if failed to parse it (malformed json?), return raw string -
          // it will be displayed correctly (as a string) then
          return value;
        }
      }
    },
  },
}
</script>
