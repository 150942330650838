<template>
  <div>
    <div class="d-flex align-items-center ml-1">
      <div :id="'settings-' + id" v-b-hover="hoverHandler">
        <BIconGear v-if="!settingsIcoHover" variant="secondary" role='button'  font-scale="1" class="mr-1"/>
        <BIconGear v-else variant="primary" role='button'  font-scale="1" class="mr-1"/>
        <b-popover
            triggers="click"
            :target="'settings-' + id"
            placement="bottomright"
            :show.sync="popoverShow"
            @show="popoverBeforeShow"
        >
          <b-overlay :show="showOverlay" rounded="sm" spinner-variant="primary" spinner-type="grow">
          Brightness
          <b-form-input
              type="range"
              min="20" max="255" step="1"
              @change="brightnessChange"
              v-model.number="brightness"
          />
          <div v-if="isRgb">
            Color
            <div class="text-center">
              <b-button-group size="sm">
                <b-button v-for="color in colors"
                          v-bind:key="color.hex"
                          @click="setColorXY(color.x, color.y)"
                          :style="'background-color: ' + color.hex"
                          class="btn-outline-dark cc-btn-color"
                />
              </b-button-group>
            </div>

            <b-input-group size="sm" class="mt-2">
              <b-input-group-prepend is-text>X</b-input-group-prepend>
              <b-form-input type="number" value="0" min="0" max="65535" v-model="colorX" />
              <b-input-group-prepend is-text>Y</b-input-group-prepend>
              <b-form-input type="number" value="0" min="0" max="65535" v-model="colorY" />
              <b-input-group-append>
                <b-button variant="outline-secondary" @click="setColorXY(colorX, colorY)">Set</b-button>
              </b-input-group-append>
            </b-input-group>

            <b-input-group size="sm">
              <b-input-group-prepend is-text>R</b-input-group-prepend>
              <b-form-input type="number" value="0" min="0" max="255" v-model="colorR" />
              <b-input-group-prepend is-text>G</b-input-group-prepend>
              <b-form-input type="number" value="0" min="0" max="255" v-model="colorG" />
              <b-input-group-prepend is-text>B</b-input-group-prepend>
              <b-form-input type="number" value="0" min="0" max="255" v-model="colorB" />
              <b-input-group-append>
                <b-button variant="outline-secondary" @click="setColorRGB(colorR, colorG, colorB)">Set</b-button>
              </b-input-group-append>
            </b-input-group>

            <b-form-checkbox
                switch
                v-model="discoEnabled"
                @change="discoModeChange"
                class="mt-2"
            >
              Disco
            </b-form-checkbox>

            <div class="text-center">
              <b-button @click="closePopover" size="sm" variant="outline-danger">Close</b-button>
            </div>

          </div> <!-- end of isRGB -->
          </b-overlay>
        </b-popover>
      </div>
      <b-form-checkbox
          switch
          v-model="onOffState"
          @change="onOffStateChange"
          class="mr-1"
      >
        {{displayName}}
      </b-form-checkbox>
      <BIconAlarm v-if="turnOffAt" v-b-tooltip.hover :title="turnOffAtTooltip" />
    </div>
  </div>
</template>


<script>
import { BIconGear, BIconAlarm } from 'bootstrap-vue'
import { DateTime } from "luxon";

export const predefinedColors = [
  { hex: "#f3e7d3", x: 30015, y: 26870 },
  { hex: "#ffffff", x: 21150, y: 21563 },
  { hex: "#ff0000", x: 45914, y: 19615 },
  { hex: "#007700", x: 11299, y: 48941 },
  { hex: "#0000ff", x: 8880, y: 2613 },
  { hex: "#ffff00", x: 29108, y: 33853 },
  { hex: "#770077", x: 25262, y: 10134 },
  { hex: "#ff7700", x: 40056, y: 24578 },
  { hex: "#9000ff", x: 15840, y: 5809 },
];

export default {
  name: 'LightSourceControl',
  components: {
    BIconGear,
    BIconAlarm,
  },

  props: {
    showOverlay: Boolean,
    id: String,
    displayName: String,
    isRgb: Boolean,

    state: String,
    level: Number,
    disco: Boolean,
    turnOffAt: Number,
  },

  data: function() {
    return {
      settingsIcoHover: false,
      popoverShow: false,
      colors: predefinedColors,

      onOffState: false,
      brightness: 100,
      colorX: 0,
      colorY: 0,
      colorR: 0,
      colorG: 0,
      colorB: 0,
      discoEnabled: false,
    }
  },

  computed: {
    turnOffAtTooltip() {
      if (this.turnOffAt) {
        const turnOffAtDt = DateTime.fromSeconds(this.turnOffAt);
        const diffFromNow = turnOffAtDt.diffNow();
        const diffFromNowMinutes = diffFromNow.as('minutes') | 0;
        const timeStr = turnOffAtDt.toLocaleString(DateTime.TIME_WITH_SECONDS);
        return "Turn off at " + timeStr + " \nin " + diffFromNowMinutes + ' minute(s)';
      }
      return null;
    }
  },

  created() {
    this.onOffState = this.state === "On";
    this.brightness = this.level;
    this.discoEnabled = this.disco;
  },

  watch: {
    state() {
      this.onOffState = this.state === "On";
    },
    level() {
      this.brightness = this.level;
    },
    disco() {
      this.discoEnabled = this.disco;
    },
  },

  methods: {
    hoverHandler(isHovered) {
      this.settingsIcoHover = isHovered;
    },

    popoverBeforeShow() {
      this.$root.$emit('bv::hide::popover');
    },

    closePopover() {
      this.popoverShow = false;
    },

    onOffStateChange() {
      const newState = this.onOffState? "On" : "Off";
      this.$emit("update:state", newState);
      this.$emit("turn-on-off", this.id, newState);
    },

    brightnessChange() {
      if (!this.onOffState) {
        // changing the brightness automatically turns the light on
        this.onOffState = true;
        this.$emit("update:state", "On");
      }

      this.$emit("update:level", this.brightness);
      this.$emit("brightness-change", this.id, this.brightness);
    },

    setColorXY(x, y) {
      this.$emit("set-color-xy", this.id, x, y);
    },

    setColorRGB(r, g, b) {
      this.$emit("set-color-rgb", this.id, r, g, b);
    },

    discoModeChange() {
      const newState = this.discoEnabled? "On" : "Off";
      this.$emit("update:disco", this.discoEnabled);
      this.$emit("disco-mode-change", this.id, newState);
    }
  }

}

</script>

<style scoped>
  .cc-btn-color {
    height: 1.5em;
    width: 1.5em;
  }
</style>