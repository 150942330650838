<template>
  <div>
    <b-form-row class="align-items-center">
      <b-col cols="auto"><label class="col-form-label">Number of days:</label></b-col>
      <b-col cols="auto"><b-form-input type="number" min="1" max="90" v-model="days" /></b-col>
      <b-col cols="auto">
        <RefreshButton variant="outline-success" default-label="Generate report" loading-label="Generating" no-default-icon
                       :loading="reportGenerating" @click="generateRangeReport"/>
      </b-col>
    </b-form-row>

    <PeriodReportTableControl unit="Day" :report="report" :busy="reportGenerating" class="mt-3"/>
  </div>
</template>


<script>
import RefreshButton from "@/components/common/RefreshButton";
import axios from "axios";
import {showAxiosErrorToast} from "@/util/toasts";
import PeriodReportTableControl from "@/components/meters/PeriodReportTableControl";

export default {
  name: 'DailyReportTab',

  components: {
    RefreshButton,
    PeriodReportTableControl,
  },

  data: function() {
    return {
      days: 90,
      reportGenerating: false,
      report: null,
    }
  },

  methods: {
    generateRangeReport: function() {
      this.reportGenerating = true;
      let queryParams = {
        daysBack: this.days,
      }
      axios.get('/api/meters/reports/daily', { params: queryParams })
          .then( response => this.report = response.data)
          .catch( error => showAxiosErrorToast("Failed to generate daily report", error) )
          .finally( () => this.reportGenerating = false )
    }
  }
}
</script>