<!--<b-spinner variant="primary" type="grow" label="Spinning" class="mr-2"></b-spinner>-->


<template>
  <b-overlay :show="showSpinner" rounded="sm">
    <b-list-group>

      <!-- OT_SetStatus -->
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <b-button
            variant="success"
            class="mr-2"
            @click="sendCommand('OtSetStatus', 'send-set-status-cmd', setStatusParams)"
        >
          OT SetStatus
        </b-button>
        <div>
          <b-button-group size="sm">
            <b-button :pressed.sync="setStatusParams.heating" :variant="setStatusParams.heating? 'primary' : 'outline-primary'">Heating</b-button>
            <b-button :pressed.sync="setStatusParams.hotWater" :variant="setStatusParams.hotWater? 'primary' : 'outline-primary'">Hot water</b-button>
            <b-button :pressed.sync="setStatusParams.cooling" :variant="setStatusParams.cooling? 'primary' : 'outline-primary'">Cooling</b-button>
          </b-button-group>
        </div>
        <div class="mr-auto"></div>
      </b-list-group-item>

      <!-- OT_SetHeatingSetpoint -->
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <b-button
            variant="success"
            class="mr-2"
            @click="sendCommand('OtSetHeatingSetpoint', 'send-set-heating-setpoint-cmd', { temperature: setpointsParams.heatingSetpoint })"
        >
          OT SetHeatingSetpoint
        </b-button>
        <b-input-group append="℃" size="sm" class="temperature-input">
          <b-form-input type="number" min="0" v-model="setpointsParams.heatingSetpoint"></b-form-input>
        </b-input-group>
        <div class="mr-auto"></div>
      </b-list-group-item>

      <!-- OT_SetHotwaterSetpoint -->
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <b-button
            variant="success"
            class="mr-2"
            @click="sendCommand('OtSetHotwaterSetpoint', 'send-set-hotwater-setpoint-cmd', { temperature: setpointsParams.hotWaterSetpoint })"
        >
          OT SetHotwaterSetpoint
        </b-button>
        <b-input-group append="℃" size="sm" class="temperature-input">
          <b-form-input type="number" min="0" v-model="setpointsParams.hotWaterSetpoint"></b-form-input>
        </b-input-group>
        <div class="mr-auto"></div>
      </b-list-group-item>

      <!-- Getters: OT_GetParams, OT_GetSecondaryParams, OT_GetStats -->
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <div>
          <b-button variant="info" class="mr-2" @click="sendCommand('OtGetParams', 'send-get-params-cmd')">OT GetStatus</b-button>
          <b-button variant="info" class="mr-2" @click="sendCommand('OtGetSecondaryParams', 'send-get-secondary-params-cmd')">OT GetSecondaryParams</b-button>
          <b-button variant="info" class="mr-2" @click="sendCommand('OtGetStats', 'send-get-stats-cmd')">OT GetStats</b-button>
        </div>
        <div class="mr-auto"></div>
      </b-list-group-item>

    </b-list-group>
  </b-overlay>
</template>


<script>
import axios from 'axios'
import {showInfoToast, showAxiosErrorToast} from "@/util/toasts";

export default {
  name: 'OpenThermCommandsControl',

  data: function() {
    return {
      setStatusParams: {
        heating: false,
        hotWater: true,
        cooling: false,
      },
      setpointsParams: {
        hotWaterSetpoint: 60,
        heatingSetpoint: 45,
      },
      showSpinner: false
    }
  },

  methods: {
    sendCommand: function(otName, pathName, params) {
      this.showSpinner = true;
      axios.post('/api/opentherm/' + pathName, params)
        .then(response => {
          showInfoToast(otName + " command sent successfully with id " + response.data.commandId);
        })
        .catch(err => {
          showAxiosErrorToast("Failed to send " + otName, err)
        })
        .finally(() => {
          this.showSpinner = false;
        })
    }
  }
}
</script>


<style scoped>
  .temperature-input {
    width: 6em;
  }
</style>