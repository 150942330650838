<template>
  <b-overlay :show="!climateData" rounded="sm" spinner-variant="primary" spinner-type="grow">
    <b-card>
      <template #header>
        <h5 class="mb-0">☀️ Climate</h5>
      </template>

      <b-list-group v-if="climateData && climateData.sensors" flush>
        <ClimateDashboardItemControl
            v-for="climateItem in climateData.sensors"
            v-bind:key="climateItem.location"
            :location="climateItem.location"
            :temperature="climateItem.temperature"
            :humidity="climateItem.humidity"
            :updated-at-epoch="climateItem.updatedAt"
            :ico-type="climateItem.recencyIndicator"
        />
      </b-list-group>
    </b-card>
  </b-overlay>
</template>

<script>
import ClimateDashboardItemControl from "@/components/climate/ClimateDashboardItemControl";

export default {
  name: 'ClimateDashboardControl',
  components: {
    ClimateDashboardItemControl,
  },
  props: {
    climateData: Object,
  },
}
</script>